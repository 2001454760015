// Abstracts
@use "abstracts/index" as *;

// --- typeahead stuff

/* Typeahead.js */

.twitter-typeahead {
    display: block !important;
}

.tt-hint {
    color: #999;
}

.tt-dropdown-menu {
    margin-top: box-model(xs);
    padding: box-model(m, 0);
    background-color: color(base-000);
    border: 1px solid color(base-500);
    border-radius: $base-border-radius;
    box-shadow: $base-box-shadow;
    max-width: $dropdowns-max-width;
    min-width: $dropdowns-min-width;
}

.tt-suggestion {
    padding: box-model(xs, $dropdowns-side-paddings);
}

.tt-suggestion.tt-cursor {
    cursor: pointer;
    background-color: color(primary-100);
}

.tt-suggestion p {
    @include ellipsis;
    white-space: nowrap !important;
    margin: 0;
}
