// Abstracts
@use "abstracts/index" as *;

/// Here goes the dark mode overrides.
/// Please consider only using this file for dark mode specific overrides.
///
/// By overrides, we mean changing the color of a component or element that is already using the `color(...)` function.
/// but the result is not good enough for dark mode.
///
/// !!! IMPORTANT !!!
///
/// A color that "remains" white in dark mode does not constitute a dark mode override.
/// Check the color in the light mode and see if it is using the `color(...)` function instead.

@media (prefers-color-scheme: dark) {
    html:not(.theme-light) {
        &:root {
            @include dark-theme;
        }
    }
}

.theme-light {
    .dark-only {
        display: none !important;
    }
}

.theme-dark {
    &:root {
        @include dark-theme;
    }

    .light-only {
        display: none !important;
    }

    body .v--modal,
    .v--modal-box {
        background-color: color(base-050);
    }

    @each $type in primary, secondary, danger, warn, alert {
        .btn:not(.btn-#{$type}) {
            &.disabled, &[disabled] {
                background-color: color(base-500);
                color: color(base-600);
            }
        }
    }

    nav.main .top-level-menu > ul > li.selected > a,
    nav.main .top-level-menu > ul > li.selected:hover > a,
    nav.main .submenu > ul > li.selected > a,
    nav.main .submenu > ul > li.selected.hover > a,
    nav.main .submenu > ul > li.selected:hover > a,
    .nav-list > li.active > a {
        background-color: color(primary-100);
    }

    .user-menu .support,
    .user-menu .activator {
        color: light-color(base-000);
    }

    .marketplace .list-tile {
        background-color: color(base-050);
    }

    .btn.primary,
    .badger-wizard .wizard-actions button.primary,
    .primary.button-and-dropdown,
    .btn.btn-primary,
    .badger-wizard .wizard-actions button.btn-primary,
    .content-wrapper.analytics .well button.btn,
    .content-wrapper.analytics .well .badger-wizard .wizard-actions button,
    .badger-wizard .wizard-actions .content-wrapper.analytics .well button,
    .table-network-configuration-editor .btn.btn-mini,
    .table-network-configuration-editor .badger-wizard .wizard-actions button.btn-mini,
    .badger-wizard .wizard-actions .table-network-configuration-editor button.btn-mini,
    .btn-primary.button-and-dropdown,
    .content-wrapper.analytics .well button.button-and-dropdown,
    .table-network-configuration-editor .button-and-dropdown.btn-mini {
        background-color: color(primary-100);
        color: color(base-700);

        &:hover {
            background-color: color(primary-300);
        }
    }

    .btn.danger, .badger-wizard .wizard-actions button.danger, .condition-rule .predicate:not(.editing) .btn.outer-wrapper:hover, .condition-rule .predicate:not(.editing) .badger-wizard .wizard-actions button.outer-wrapper:hover, .badger-wizard .wizard-actions .condition-rule .predicate:not(.editing) button.outer-wrapper:hover, .danger.button-and-dropdown, .condition-rule .predicate:not(.editing) .button-and-dropdown.outer-wrapper:hover, .btn.btn-danger, .badger-wizard .wizard-actions button.btn-danger, .btn-danger.button-and-dropdown {
        background-color: color(error-100);
        color: color(base-700);

        &:hover {
            background-color: color(error-300);
        }
    }

    .btn.secondary, .badger-wizard .wizard-actions button.secondary, .secondary.button-and-dropdown, .btn.btn-secondary, .badger-wizard .wizard-actions button.btn-secondary, .btn-secondary.button-and-dropdown, .btn.btn-success, .badger-wizard .wizard-actions button.btn-success, .btn-success.button-and-dropdown {
        background-color: color(success-100);
        color: color(base-700);

        &:hover {
            background-color: color(success-300);
        }
    }

    .alert {
        background-color: transparent !important;
    }

    .vue-notification-group .vue-notification {
        &, .close {
            color: light-color(base-000);
        }
    }

    .dropdown-stream-links .menu {
        background-color: color(base-300);
        border-color: color(base-100);
    }

    small:not(.important),
    .list-tile .description,
    .button-card .subtitle {
        color: color(base-600)
    }

    .event-dashboard .badge.danger {
        background-color: color(base-300);
    }

    .quick-links {
        background-color: color(base-300);
        border-color: color(base-305);
    }

    .rd-back, .rd-next {
        color: color(base-700);
    }

    .upload-manager {
        background-color: color(base-300);
    }

    .mx-time-column .mx-time-item {
        color: color(base-700);

        &.disabled {
            color: color(base-300);
            background-color: color(base-050);
        }

        &.active {
            color: color(primary-300);
            background-color: color(primary-050);
        }
    }
}
