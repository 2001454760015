// Abstracts
@use "abstracts/index" as *;

.theme-manager:not(.page) {
    @include grid(12);

    form.main-form, .apps-preview {
        @include cols(8, 3);
    }

    .theme-elements {
        @include grid(2);

        .form-group {
            @include cols(1);
            margin-bottom: 0;
        }
    }

    a.more {
        display: inline-block;
        margin-top: box-model(l);

        i {
            margin-left: box-model(xxs);
            vertical-align: middle;
        }
    }
}

.theme-assets-manager:not(.page) {
    @include grid(12);

    form.main-form {
        @include cols(8, 3);
        @include grid(2);
    }

    .app-icon {
        @include cols(1);

        .image {
            width: 50%;
        }
    }

    .apps-preview {
        @include cols(8, 3);
    }

    .environments {
        margin-top: box-model(m);
    }
}

.import-theme {
    .modal-body {
        overflow: visible;
    }

    .alert {
        margin-bottom: 0;
    }
}
