// Abstracts
@use "abstracts/index" as *;

.theme-editor-theme-preview.mini {
    width: 300px;
    text-align: left;
    display: inline-block;
    cursor: pointer;
    background: #e6e6e6;
    font-size: 0.8em;
    border-radius: 4px;
    border: 2px solid #e6e6e6;
    transition: border-color .1s ease;

    &.selected {
        border-color: #34a0ed;
    }

    .spotman {
        float: left;
        width: 30%;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;

        &-row {
            padding: 5px;

            i {
                margin-right: 5px;
            }

            &.separator {
                text-transform: uppercase;
                padding: 3px 5px;
                font-size: 0.8em;
            }

            &.profile {
                .media {
                    display: table;
                    &-left, &-body {
                        display: table-cell;
                    }

                    &-body {
                        line-height: 1.1em;
                    }
                }

                small {
                    font-size: 0.9em;
                    opacity: 0.9;
                }
            }
        }
    }

    .titlebar {
        border-top-right-radius: 4px;
        text-align: center;
        font-weight: bold;
        padding: 5px 0;
    }

    .home {
        float: left;
        width: 70%;
    }

    .home-block {
        background: #fff;
        width: 190px;
        height: 40px;
        box-shadow: #c0c0c0 0 1px 5px;
        box-sizing: border-box;
        margin: 10px;
        border-radius: 4px;
    }
}
