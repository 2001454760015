// Abstracts
@use "abstracts/index" as *;

.html-editor {
    border-radius: $base-border-radius;

    &.disabled {
        cursor: not-allowed;

        .ql-toolbar {
            opacity: .6;
            pointer-events: none;
        }

        .ql-editor {
            color: color(base-500);
            background-color: color(base-100);
        }
    }

    &:focus-within {
        outline: none;
        border-color: color(primary-300);
        box-shadow: 0 0 0 1px color(primary-300);
    }

    .ql-formats {
        margin-right: box-model(xs) !important;
    }

    .ql-editor {
        max-height: $html-editor-max-height;
    }
}
