// Abstracts
@use "abstracts/index" as *;

.navspotman {
    .nav-spotman-editor-view .column-nav-spotman-imitation {
        z-index: 1;
    }

    .nav-spotman-editor-toolbar {
        width: auto;
        float: none;
    }

    .nav-spotman-stuff-buttons {
        width: auto;
        float: none;
    }
}
