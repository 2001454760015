// Abstracts
@use "abstracts/index" as *;

.video-processing-status {
    padding: box-model(xxs);
    margin-left: 0;
    text-align: left;

    .error, .processing {
        p {
            margin-bottom: 0;
            width: fit-content;
            @include font-size(s);

            &.badge {
                margin: 0;
                @include font-size(xs);
                @include font-weight(semibold);
                @include line-height(xxs);
                text-transform: uppercase;
                border-radius: box-model(xxs);
                padding: 0 box-model(xxs);
            }
        }
    }

    .error p.badge {
        background-color: color(error-100);
        color: color(error-300);
    }

    .processing p.badge {
        background-color: color(base-300);
    }
}

