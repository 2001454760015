// Abstracts
@use "abstracts/index" as *;
@use "default/components/cards/base" as *;

.entitlement-plan-card {
    @extend %card-with-borders;
    padding: box-model(m);

    header {
        @include clearfix;
        margin-bottom: box-model(xs);

        .progress-ring {
            float: left;
            margin-right: box-model(m);
        }
    }

    .badge {
        margin: 0 0 box-model(xs);
    }

    .title {
        @include font-size(xm);
        @include line-height(m);
    }

    .subtitle {
        color: color(base-500);
    }

    .name {
        @include font-size(document);
        @include line-height(base);

        margin: box-model(0, 0 ,xxs);
    }

    .id {
        margin: box-model(0, 0 ,xxs);
    }

    .id, .expiration {
        @include font-size(s);
        @include line-height(xxs);
        color: color(base-500);
    }

    .progress-ring .prog {
        transition: stroke-dashoffset 1s ease-in-out;
    }
}
