// Abstracts
@use "abstracts/index" as *;

.contents-detail {
    > header {
        margin-bottom: box-model(xl);

        h1 {
            @include ellipsis(true);
        }
    }

    .contents {
        display: flex;

        .content {
            width: grid-col-width(8);
        }

        .video-preview, .document-preview {
            margin-left: box-model(xl);
            width: grid-col-width(6);

            .media-player {
                .smvp-video-player {
                    border-radius: 5px;
                }
            }
        }

        .video-processing {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            max-height: 100%;
            width: 100%;
            background-color: #000;
            border-radius: 5px;
            padding-bottom: 56.25%;

            .video-processing-status {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                width: 100%;

                .processing {
                    width: 100%;
                }

                .status {
                    text-align: left;
                    margin: auto;
                    color: white; // Fixed color for both light and dark mode
                }

                .animated-status {
                    width: 170px; // arbitrary width bigger than the text to prevent the 3 dots animation from resizing the container
                }
            }
        }

        .smvp-video-player {
            border: 1px solid color(base-300);
        }
    }
}
