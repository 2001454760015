// Abstracts
@use "abstracts/index" as *;

%base-tag {
    @include font-size('xs');
    @include font-weight('bold');
    @include line-height('xxs');
    padding: box-model(0, xxs);
    display: inline-block;
    border-radius: $base-border-radius;
}

.tag.green {
    color: color(success-500);
    background-color: color(success-100);
}

.tag.green {
    @extend %base-tag;
}
