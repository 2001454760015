// Abstracts
@use "abstracts/index" as *;

.mailing {
    h1 .count {
        color: color(primary-300);
        margin-right: box-model(s);
    }

    .mailing-summary {
        margin-bottom: box-model(l);
    }

    .mailing-details {
        th {
            text-align: left;
        }
    }
}
