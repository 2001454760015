// Abstracts
@use "abstracts/index" as *;

.anonymized-alert {
    h5 {
        a {
            @include font-weight(normal);
            float: right;
        }
    }

    p {
        margin: 0;
        margin-top: box-model(s);
    }
}
