// Abstracts
@use "abstracts/index" as *;

.tabbed-charts {
    .buttons-toggler {
        margin-bottom: box-model(m);
    }

    .metrics {
        margin-top: box-model(m);

        > header h4 {
            text-transform: uppercase;
            color: color(primary-300);

            i {
                @include font-size(document);

                display: inline-block;
                vertical-align: middle;
                margin: box-model(-xxxs, 0, 0, xxs);
            }

            &.open {
                i {
                    transform: rotate(180deg);
                }
            }
        }

        > section {
            @include grid(10);
        }
    }

    .metric {
        @include cols(2);
        @include font-size(xs);
        @include line-height(xxxs);

        color: color(base-600);

        .name {
            color: color(base-700);
        }

        .color {
            border-radius: 100%;
            height: box-model(xs);
            width: box-model(xs);
            margin-right: box-model(xs);
            display: inline-block;
            vertical-align: middle;
        }

        .description {
            padding-left: box-model(m);
        }
    }
}
