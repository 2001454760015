// Abstracts
@use "abstracts/index" as *;

[bs-colourpicker],
[data-bs-colourpicker] {
    display: block;
    position: relative;

    // Sometimes a sibling, sometimes a child
    input[type="text"]:not(.form-control),
    & + input[type="text"]:not(.form-control) {
        padding-left: box-model(xl);
    }
}

.form-group.color-picker {
    input[type="text"] {
        padding-left: box-model(xl);
    }
    position: relative;
}
