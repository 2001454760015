// Abstracts
@use "abstracts/index" as *;

.report-card {
    background-color: color(base-000);
    display: block;
    border: 1px solid color(base-300);
    border-radius: $base-border-radius;
    padding: box-model(m);
    cursor: pointer;
    box-shadow: box-model(0, xxxs, xxxs) colorAlpha(base-700, 0.04);
    transition: border-color .2s ease-in-out;

    &:hover {
        border-color: color(primary-300);

        h3 {
            color: color(primary-300);
        }
    }

    h3 {
        @include font-size(document);
        @include font-weight(bold);
        @include line-height(base);

        border: 0;
        transition: color .2s ease-in-out;
    }
}
