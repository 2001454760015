@use "sass:math";

// Abstracts
@use "abstracts/index" as *;

.email-form, .email-editor-page {
    $margin-from-bottom: box-model(xxl);

    height: calc(100vh - #{$page-content-distance-from-top + $stream-controls-height + $margin-from-bottom});
    display: flex;
    flex-direction: column;

    > .config {
        flex: 0 0 auto;
    }

    > .unlayer-wrapper {
        flex: 1 1 100%;
        contain: content;
        overflow: hidden;
    }

    .toggler {
        margin-top: box-model(xl);
    }

    .editor-label {
        margin-top: box-model(l);
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .basic-editor-switch {
        cursor: pointer;
    }

    .switch-fullscreen {
        position: fixed;
        top: 5px; // Unit hardcoded because of the 3rd party desing system
        left: 105px; // Unit hardcoded because of the 3rd party desing system
        z-index: z-index(notifications);
    }

    .unlayer-fullscreen {
        position: fixed;
        height: 100vh;
        top: 0;
        left: 0;
        width: 100vw;
        z-index: z-index(notifications);
    }

    .unlayer-editor {
        height: 100%;
        border: 1px solid color(base-300);
        border-radius: $base-border-radius;
    }
}

.emails-list {
    .empty-state {
        text-align: center;
        margin: box-model(h, 0);

        img {
            margin-bottom: box-model(m);
        }
    }
}
