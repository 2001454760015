// Abstracts
@use "abstracts/index" as *;

.metadata-editor {
    position: relative;

    h3 {
        border-bottom: none;
    }

    .switch {
        position: absolute;
        top:0;
        right:0;
    }

    .type-selection {
        margin-bottom: box-model(xxl);
    }

    .editor-caption {
        @include font-size(s);
        @include line-height(xs);
        color: color(base-600);
    }
}
