@use "sass:math";

// Abstracts
@use "abstracts/index" as *;

.live-stream-video-picker {
    .smart-table {

        margin-top: box-model(m);

        table.table-striped {

            & > tbody {
                & > tr.selected > td {
                    background-color: color(primary-050);
                }

                td.video-thumbnail {
                    width: #{box-model(h) * 2};

                    .outer-wrapper {
                        padding-top: box-model(xxxs);
                        height: auto;
                    }

                    img {
                        margin: 0 auto;
                        height: box-model(h);
                        border-radius: box-model(xxs);
                    }
                }

                td.date {
                    max-width: box-model(xxl);
                }
            }
        }
    };

    .library-upload {
        margin-bottom: box-model(m);
    }

    .empty-video-library-message {
        margin: box-model(m);
        @include font-weight(semibold);
    }
}

.video-list-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .no-videos {
        display: flex;
        flex-direction: column;
        padding: box-model(xs);
        justify-content: center;
        align-items: center;
        height: 100%;

        .icon {
            padding-bottom: box-model(xs);
        }
        i {
            font-size: icon-size(xs);
        }
    }

    .with-videos {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;

        .info-panel {
            margin: box-model(xs);
            padding: box-model(xs);
            border-radius: box-model(xxs);
            border: 1px solid color(base-300);
            background-color: white;

            .info-primary {
                display: flex;
                color: color(primary-300);

                i {
                    font-size: box-model(m) * 1.25;
                    margin-right: box-model(xs);
                }
            }

            .info-secondary {
                margin-top: box-model(s);
            }
        }

        .videos-list {
            overflow-y: auto;
            overflow-x: hidden;
            justify-content: flex-start;
            align-items: center;
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        .action {
            display: flex;
            flex-direction: column;
            gap: box-model(xs);
            padding: box-model(xs);

            button {
                width: 100%;
                margin: 0;
            }
        }
    }
}

.live-stream-videos {
    .video {
        display: flex;
        padding: box-model(xs) box-model(l);
        padding-bottom: 0;
        flex-wrap: wrap;
        align-items: center;

        &.playing {
            background-color: white;
            border-bottom: 1px solid color(base-300);
            padding-bottom: box-model(l);
            padding-top: box-model(m);
        }

        .list-title {
            margin-bottom: box-model(xs);
            font-weight: bold;
            width: 100%;
        }

        .drag-handler {
            box-sizing: border-box;
            margin-left: - box-model(s);
            cursor: pointer;

            i {
                font-size: box-model(m);

                &.drag-disabled {
                    color: color(base-500);
                    cursor: default;
                }
                &.drag-hide {
                    opacity: 0;
                }
            }
        }

        &.sortable-chosen { //added by vue-draggable
            color: color(primary-300);
            .panel-video-thumbnail {
                border: 1px solid color(primary-300);
            }
        }

        .action-buttons {
            width: 100%;
            margin-left: 0px;
            margin-top: box-model(xs);

            .live-toggler {
                width: 100%;
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .panel-video-thumbnail {
        flex: 1;
        position: relative;
        display: block;
        width: #{box-model(h) * 3.3};
        height: #{box-model(h) * 3.3 * math.div(9, 16)};
        border: 1px solid color(base-300);

        .video-thumbnail {
            height: 100%;
            background-size: cover;
        }

        .badges {
            display: flex;
        }

        .badge {
            margin: box-model(xxs);

            &.time {
                color: white;
                background-color: color(base-600);
                margin-left: auto;
            }
        }

        .thumbnail-processing-overlay {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: colorAlpha(base-700, 0.5);
        }

        .thumbnail-overlay {
            opacity: 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: colorAlpha(base-700, 0.5);

            button {
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &:hover {
            .hover-buttons, .thumbnail-overlay {
                opacity: 1;
            }
        }

        .hover-buttons {
            position: absolute;
            bottom: 0;
            opacity: 0;
            transition: opacity .15s ease-in-out;
            color: color(base-000);
            display: flex;
            padding: box-model(xs);
            background: colorAlpha(base-700, 0.65);
            width: 100%;
            box-sizing: border-box;
            border-radius: box-model(xxs);

            .title-text {
                @include font-size(s);
                flex-grow: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                flex: 100%;
                padding: 0 box-model(xs);
            }

            .preview-video {
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(255, 255, 255, .55);
                border: 0;
                border-radius: 100%;
                height: 24px;
                width: 24px;
                font-size: 12px;
                cursor: pointer;
                color: color(base-700);
            }

            .delete-video {
                flex-basis: auto;
                padding: 0 box-model(xs);
                cursor: pointer;
                color: white;
                appearance: none;
                border: 0;
                background-color: transparent;
                margin-left: auto;
            }
        }
    }

    .video-processing-status {
        p.status {
            color: white;
        }
    }
}

.input-switch-player {

    .input-info-banner {
        position: absolute;
        top: box-model(l);

        width: 240px;
        left: 50%;
        transform: translateX(-50%);

        font-weight: 600;

        p {
            padding: box-model(s) box-model(xs);
            background-color: color(base-000);
            border-radius: box-model(xxs);
            box-shadow: #{0 - box-model(xxxs)} box-model(xxs) box-model(s) colorAlpha(base-700, 0.05);
        }
    }
}

.remove-live-modal {
    .restore-stage {
        display: flex;
    }
}

.video-preview-modal {
    h1.title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
