@use "editors/creative-editor";
@use "editors/email-editor";
@use "editors/html-editor";
@use "editors/image-cropper";
@use "editors/json-editor";
@use "editors/list-quick-editor";

.metalist {
    .element {
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: box-model(m);

        .descriptor {
            flex: 1 1 auto;
            margin: 0;
        }

        .remove {
            margin-left: box-model(s);
        }

        .form-group {
            flex: 1 1 auto;
            margin-bottom: 0;
        }
    }
}
