// Abstracts
@use "abstracts/index" as *;

.webinar-template-editor {

    .tabbed-views {

        ul.nav {
            margin-top: box-model(m);
        }

        & > .form-container {
            width: box-model(xs) * 85;
            padding: box-model(l, xxl, 0);
        }

        & > footer {
            margin-top: box-model(m);
            text-align: right;
            padding: box-model(0, xxl);
            width: box-model(xs) * 85;
        }

    }

}
