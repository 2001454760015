// Abstracts
@use "abstracts/index" as *;

.type-representations {
    border: 1px solid color(base-300);
    border-radius: $base-border-radius;
    padding: box-model(xs, s);
    margin-bottom: box-model(xxl);

    .type-representation {
        display: none;
        // This is to display the first represenation (second p in the block)
        &:nth-of-type(2) {
            display: block;
        }
    }

    &.show-full-representations {
        .type-representation {
            display: block;
        }
    }
    a.has-action i {
        vertical-align: middle;
        margin-left: box-model(xxs);
    }
}
