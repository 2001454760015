// Abstracts
@use "abstracts/index" as *;

.theme-editor.invisible-until-custom-styles-loaded {
    visibility: visible;
}

$sidebar-width: 300px;
$editor-width: 1024px;
$home-preview-width: $editor-width - $sidebar-width;

.theme-editor {
    position: relative;

    .appearance-selection-wrapper {
        margin-left: -16px;
        margin-right: -16px;
    }

    .appearance-selection {
        max-width: 30%;
        padding-left: 16px;

        .icon-info-sign {
            margin-left: 3px;
        }
    }

    .theme-wizard-banner {
        // cursor: pointer;
        max-width: $editor-width;
        margin: 0 auto 20px auto;
        box-sizing: border-box;
    }

    > .loading {
        text-align: center;
        padding: 100px 0 0 0;
        position: absolute;
        width: $editor-width;
        margin: 0 25px;

        .new-spinner {
            margin-right: 5px;
        }
    }

    .possible-fonts-issues-warning {
        margin: 0 15px;

        .alert a {
            color: inherit;
        }
    }

    .app-preview {
        $height: 430px;

        border-bottom: 1px solid #ddd;
        border-radius: $large-border-radius;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        height: $height;
        margin: 0 auto;
        overflow: hidden;
        text-align: center;
        user-select: none;
        white-space: nowrap;
        width: $editor-width;

        .themable {
            transition: background-color .3s ease, color .3s ease;
        }

        .spotman, .home {
            text-align: left;
            display: inline-block;
            float: none;
            vertical-align: top;
            white-space: normal;
        }

        .spotman, .home-wrapper {
            height: $height;
        }

        .spotman {
            width: $sidebar-width;
            float: left;
            border-left: 1px solid #ddd;
            box-sizing: border-box;

            // border-bottom-left-radius: 10px;

            .spotman-row {
                padding: 12px 0;
                position: relative;

                .icon {
                    margin: 0 15px;
                    vertical-align: baseline;
                    font-size: 120%;
                    width: 15px;
                    text-align: center;
                }

                &.profile-row {
                    overflow: hidden;
                    padding: 15px;
                    background-color: rgba(0,0,0,0.4) !important;

                    .profile-photo {
                        $photo-size: 64px;
                        display: block;
                        background: #A2ABB1;
                        background-size: contain;
                        height: $photo-size;
                        width: $photo-size;
                        margin: 0 0 10px 0;

                        &.circle {
                            border-radius: 50%;
                        }
                    }

                    .event-name {
                        font-size: 80%;
                    }
                }

                .notification-badge {
                    position: absolute;
                    border-radius: 10px;
                    padding: 0px 8px;
                    font-size: 0.7em;
                    margin: -5px 0 0 25px;
                    line-height: 1.5em;
                }

                .count-badge {
                    border-radius: 10px;
                    padding: 1px 10px;
                    font-size: 0.8em;
                    margin-left: 5px;
                    line-height: 1.3em;
                }
            }

            .spotman-separator {
                min-height: 1px;
            }

            .spotman-separator-with-text {
                text-transform: uppercase;
                padding: 5px 10px;
                font-size: 80%;
            }
        }

        .home {
            width: $home-preview-width;
            float: left;
            background: #e6e6e6;

            .toolbar {
                text-align: center;
                padding: 10px 0;
            }

            .blocks {
                width: 80%;
                margin: 0 auto;

                .banner {
                    height: 200px;
                    background: #fff;
                }

                .block-row {
                    @include font-size(s);
                    @include line-height(xxxs);

                    margin: 10px 0;

                    .block-v-group {
                        .block.half-width.first-v {
                            margin-bottom: 10px;
                        }
                    }
                }

                .block {
                    background: #fff;
                    border-radius: 4px;
                    box-shadow: #c0c0c0 0 1px 5px;
                    box-sizing: border-box;

                    &.full-width { width: 100%; }
                    &.half-width {
                        float: left;
                        width: 50%;

                        &.first {
                            width: 277px;
                            margin-right: 10px;
                        }
                    }
                    &.single-height { height: 70px; }
                    &.double-height { height: 110px; }
                }

                .checkmark {
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 25px 25px 0;
                }
            }
        }

        .advanced-section {
            position: relative;
            display: none;
        }

        &.show-advanced .advanced-section {
            display: block;

            &.count-badge {
                display: inline-block;
            }
        }

        &.show-advanced .non-advanced-section {
            display: none;
        }
    }

    .app-preview-container + .colour-board {
        border: 1px solid color(base-300);
        border-top: 0;
        border-radius: $large-border-radius;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding: box-model(m);
    }

    .colour-board {
        width: $editor-width;

        margin: 0 auto box-model(l);
        padding-top: box-model(m);
        box-sizing: border-box;

        hr {
            margin: 0;
            border-top-color: #ddd;
        }

        h4 {
            margin-bottom: 0;
            padding-bottom: 5px;
            cursor: default;

            a:hover {
                text-decoration: none;
            }

            i.icon-circle-blank, i.icon-info-sign {
                text-indent: 0;
                margin-left: 5px;
            }
            i.icon-circle-blank {
                opacity: 0.3;

                &:hover {
                    opacity: 1;
                }
            }
            i.icon-info-sign {
                vertical-align: middle;
            }
        }

        ng-form {
            display: flex;
            flex-wrap: wrap;
            margin-left: - box-model(m);
            margin-right: - box-model(m);

            .form-horizontal {
                flex: 1 1 30%;
                max-width: 30%;
                padding-left: box-model(m);
                padding-right: box-model(m);
                margin-bottom: box-model(xl);
            }

            .advanced-settings {
                width: 100%;
                display: flex;
                flex-wrap: wrap;

                .fonts-uploader {
                    flex: 1 1 100%;
                    padding: 0 box-model(m);

                    .possible-fonts-issues-warning {
                        margin: box-model(m, 0);
                    }
                }
            }
        }
    }
}

.theme-editor-highlight {
    position: absolute;
    z-index: 9999;
    border: 4px solid #ffff02;
    // border: 4px solid #2b9ef0;
    border-radius: 4px;
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.3), 0 1px 3px rgba(0, 0, 0, 0.5);
}

.theme-importer {
    .icon-wrapper {
        display: inline-block;
        width: 96px;
        height: 96px;
        background: #f3f3f3;
        margin-bottom: 5px;

        img {
            opacity: 0;
            transition: opacity .3s ease;
        }
    }

    .theme-editor-theme-preview.mini {
        cursor: default;
    }
}
