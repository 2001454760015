// Abstracts
@use "abstracts/index" as *;

.content-hub-marketing {
    .heading {
        @include grid(12);
    }

    header {
        @include cols(6, 4);
        text-align: center;

        p {
            @include font-size(document);
        }
    }

    .features {
        @include grid(3);
        gap: 0;

        .card {
            @include font-size(s);
            @include line-height(document);

            border: 0;
            border-right: 1px solid color(base-300);
            padding: box-model(0, l);

            &:last-child {
                border-right: 0;
            }
        }

        .title {
            text-align: center;
            color: color(alert-300);
        }
    }

    h1, p {
        @include cols(3);
    }

    .banner, .actions, .more {
        text-align: center;
    }

    .actions {
        margin-bottom: box-model(l);
    }

    .more {
        margin-bottom: box-model(xl);
    }

    .gains {
        @include grid(12);

        .card {
            @include cols(3);
            padding: box-model(m);
        }

        .title {
            @include font-weight(semibold);
            display: flex;
            gap: box-model(xs);
            align-items: center;
            margin-bottom: box-model(s);
        }

        .icon {
            color: color(primary-300);
        }

        .body {
            @include font-size(s);
            @include line-height(document);

            color: color(base-600);
        }

        p:last-child {
            margin-bottom: 0;
        }
    }
}
