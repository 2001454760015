// Abstracts
@use "abstracts/index" as *;

.speaks-at {
    h5 {
        .badge {
            margin-left: box-model(xs);
        }
    }

    .days {
        margin-bottom: box-model(l);

        &:last-child {
            margin-bottom: 0;
        }

        .badge {
            margin-right: box-model(xs);

            & + .badge {
                margin-left: 0;
            }

            i {
                vertical-align: middle;
            }
        }
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin-bottom: 0;
    }
}
