@use "sass:math";

// Abstracts
@use "abstracts/index" as *;

.live-displays-menu {

    .displays-disabled {
        display: flex;
        flex-grow: 1;
        align-items: center;
        flex-direction: column;
        text-align: center;
        // modal height - contents height - header height
        transform: translate(0%, calc(300px - 340px + 75px));

        .text {
            margin-top: box-model(m);
            margin-bottom: box-model(s);
        }
    }

    .expandable.display-wrapper {
        border: 1px solid color(base-300);
        border-radius: box-model(s);
        transition: border-color .15s ease-out;
        margin-bottom: box-model(l);

        &.not-empty:hover:not(:focus) {
            border-color: color(base-300);
        }

        & section.display-wrapper-content {
            opacity: 0;
            transition: max-height 0.25s ease-in-out, opacity 0.15s ease-in-out;
        }

        header {

            & .opposite .toggler {
                right: box-model(xl) * 2;
                width: box-model(l);
                height: box-model(l);

                i {
                    color: color(primary-300);
                    margin-left: 0;
                    transform: rotate(0deg);

                    &:before {
                        font-size: icon-size(xs);
                    }
                }
            }

            .display-header {
                display: flex;
                align-items: center;

                .name-text {
                    @include font-weight(semibold);
                    flex-grow: 1;
                    display: flex;
                    align-items: center;
                }

                &:not(.editing) {
                    padding: box-model(xs) box-model(s);
                }
                &.editing {
                    .input-wrapper {
                        // to be exactly on top of the parent
                        margin-top: -1px;
                        margin-left: -1px;
                        margin-right: -1px;
                        padding: box-model(xs) box-model(s);
                        border-radius: box-model(s) box-model(s) 0px 0px;
                    }

                    input {
                        @include font-weight(semibold);
                    }
                }
            }
        }

        &.open {
            header .opposite .toggler i {
                transform: rotate(180deg);
                margin-top: -4px;
            }

            section.display-wrapper-content {
                padding: 0px box-model(s) box-model(xs) box-model(s);
                opacity: 1;
                max-height: box-model(xxxl) * 25;
            }
        }
    }
}

.live-display-section {

    ul.nav.nav-pills {
        margin-bottom: box-model(s);

        li {
            padding-bottom: box-model(xs);
        }
    }

    .link-section {
        margin-bottom: box-model(m);
    }

    .name-section {
        margin-bottom: box-model(l);
    }

    .form-group.md-xs {
        margin-bottom: box-model(xs);
    }

    .live-display-show-hint {
        display: block;
        margin-bottom: box-model(m);
        transform: translateY(- box-model(xs));
    }

    .toggler-section {
        padding-bottom: box-model(l);

        .display-settings {
            label {
                margin-bottom: box-model(xs);
            }
        }
    }
    .controls-section {
        text-align: right;
        padding: box-model(m) 0;
        border-top: 1px solid color(base-300);
    }

    .polls-section {
        h4 {
            margin-top: 0px;
            margin-bottom: box-model(m);
        }

        ul {
            list-style: none;
            padding-left: 0px;
            margin-bottom: 0px;

            li {
                @include font-size(s);
                @include line-height(xxm);
                padding-left: box-model(xs);
            }

            li:not(:last-child) {
                border-bottom: 1px solid color(base-300);
            }
        }
    }

    .background-section {
        margin-bottom: box-model(l);
    }

    .session-type-buttons {
        justify-content: space-between;
        margin-bottom: box-model(m);

        button {
            width: unset;
            height: 56px;
            flex-grow: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                margin-right: box-model(xs);
            }
        }
    }

    .virtual-location-hint {
        margin-bottom: box-model(xs);
    }

    .live-display-placement-form {
        label {
            display: flex;
        }
    }
}
