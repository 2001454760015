// Abstracts
@use "abstracts/index" as *;

.live-stream-polling {
    .back-to-list {
        @include font-size(document);
    }

    .pro-tip {
        .title {
            @include font-size(base);
        }

        .body {
            @include line-height(base);
            @include font-size(document);
        }

        .close::after {
            @include font-size(xxm);
        }
    }

    .polls-list {
        .poll {
            padding: box-model(s);

            .poll-question-text {
                @include line-height(base);
                @include font-size(document);
            }
        }
    }

    .view-poll {
        .content,
        .question {
            @include font-size(document);
        }

        .answer {
            padding: box-model(s);
            background-color: color(base-000);
        }

        .answer-percentage {
            @include line-height(document);
            @include font-size(base);
        }

        .actions {
            padding: box-model(m);
        }
    }

    .create-poll {
        .question-input {
            margin-bottom: box-model(m);
        }

        label {
            @include line-height(document);
            @include font-size(base);
        }

        .form-control {
            @include line-height(base);
            @include font-size(document);

            padding: box-model(xs, s);
        }

        .add-answer {
            @include line-height(base);
            @include font-size(document);

            padding: box-model(xs, s);
        }

        .btn-icon {
            padding: box-model(xs);
            width: auto;

            i {
                @include font-size(xxm);
            }
        }
    }

    .btn {
        @include line-height(base);
        @include font-size(document);

        padding: box-model(xs, m);
    }
}
