// Abstracts
@use "abstracts/index" as *;

.assisted-session-check-in {
    .panels {
        margin-bottom: box-model(xl);

        h5 {
            margin-bottom: box-model(l);
        }
    }
}
