@use "sass:math";

// Abstracts
@use "abstracts/index" as *;

ul.wizard-steps {
    list-style-type: none;
    margin: 0 0 box-model(xl);
    padding: 0;
    display: flex;

    > li {
        @include font-weight(semibold);
        flex: 1;
        text-align: center;
        color: color(base-700);
        position: relative;

        &.active {
            & ~ li {
                .step {
                    background-color: color(base-300);
                    color: color(base-700);
                }

                &::after, &::before {
                    background-color: color(base-300);
                }
            }

            &::after {
                background-color: color(base-300);
            }
        }

        &::after, &::before {
            content: '';
            display: block;
            position: absolute;
            height: box-model(xxs);
            width: 50%;
            top: math.div($wizard-steps-size - box-model(xxs), 2);
            background-color: color(primary-300);
        }

        &::before {
            left: 0;
        }

        &::after {
            right: 0;
        }

        &:first-child::before,
        &:last-child::after{
            display: none;
        }

    }

    .step {
        @include line-height(base);
        color: color(base-000);
        background-color: color(primary-300);
        display: inline-block;
        width: $wizard-steps-size;
        height: $wizard-steps-size;
        border-radius: $wizard-steps-size;
        margin-bottom: box-model(m);
        position: relative;
        z-index: 2;
    }

    .title {
        display: block;
    }
}
