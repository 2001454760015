// Abstracts
@use "abstracts/index" as *;

.template-thumbnail {
    padding: box-model(s);

    svg {
        border-radius: $base-border-radius;
        box-shadow: 0px 0px 16px 0px hsla(0, 0%, 0%, 0.15);

        .stroked-rect {
            fill: transparent;
            stroke: color(base-300);
            stroke-width: 0.5px;
        }

        .skeleton-text {
            fill: color(base-100);
        }

        .icon-image {
            border-radius: $base-border-radius;
        }

    }
}
