// Abstracts
@use "abstracts/index" as *;

.apps-preview {
    .buttons-toggler {
        margin-bottom: box-model(m);
    }

    .mobile-preview {
        @include grid(3);

        .home {
            @include cols(1);
        }

        .menu {
            @include cols(1, 2);
        }
    }
}
