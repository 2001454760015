// Abstracts
@use "abstracts/index" as *;
@use "default/components/badges" as *;
@use "default/components/buttons" as *;
@use "default/components/forms" as *;
@use "default/components/tables" as *;

// Person package
h4 {
    hr {
        display: none;
    }
}

.control-group {
    @extend %form-group;

    .controls input.input-repr-label-template {
        display: inline-block;
        width: 95%;
    }
}

.table-network-configuration-editor {
    @extend .table-striped;

    td {
        text-align: center;

        &:first-child {
            text-align: left;
        }
    }

    .label {
        @extend .badge;
        @extend .badge-success;
    }

    .btn-mini {
        @extend .btn-primary;
    }
}

.module-preferences-view {
    .main-settings-form {
        label.text {
            margin-bottom: box-model(xs);
        }
    }
}

.metadata-manager-view {
    table {
        th {
            text-align: left;
            vertical-align: top;
        }

        td {
            vertical-align: top;

            &:last-child {
                position: relative;
            }

            > input + br {
                display: none;
            }
        }

        input:not([type="checkbox"]), select, textarea {
            margin-bottom: box-model(xs);
        }

        input.input-block-level {
            @extend %form-control;
        }

    }
}

.dynamic-extensions-display-widget {
    margin-bottom: box-model(l);
}

