// Abstracts
@use "abstracts/index" as *;

.reports-list-display {
    .report-block {
        margin-top: box-model(xl);

        h3 {
            @include font-size(document);
            font-weight: 600;
            border: none;
            padding: 0;
        }
    }

    .reports {
        @include grid(12);
    }

    .report-card {
        @include cols(4);
    }
}
