// Abstracts
@use "abstracts/index" as *;

.media-info {
    line-height: 1;

    .details {
        display: flex;
        justify-content: space-between;
        padding: box-model(m, 0);
        border-bottom: 1px solid color(base-300);
        margin-bottom: box-model(m);

        .stats {
            .stats-item {
                display: inline;
                margin-right: box-model(l);
                vertical-align: middle;

                .stats-icon {
                    display: inline;
                    margin-right: box-model(xs);
                }

                i {
                    vertical-align: middle;
                }
            }
        }
    }

    .info {
        display: flex;

        .media-info-labels {
            .media-info-label {
                margin-bottom: box-model(xs);
            }
        }
        .media-info-values {
            margin-left: box-model(s);

            .media-info-value {
                @include font-weight(semibold);
                margin-bottom: box-model(xs);
            }
        }
    }
}
