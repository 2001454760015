@use "sass:math";

// Abstracts
@use "abstracts/index" as *;

.package-details {

    .modal-header > header {
        > .actions {
            position: absolute;
            right: 0;
            top: calc(50% - #{math.div($indirect-button-heights, 2)});

            .package-button + .dropdown {
                margin-left: box-model(m);
            }
        }
    }

    .alert.alert-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 0;
        margin-top: box-model(s);

        .message {
            flex: 3;
            @include font-weight(semibold);
        }

        .actions {
            flex: 1;
            text-align: right;
        }
    }
}
