@use "sass:math";

// Abstracts
@use "abstracts/index" as *;

.countdown-placeholder {
    $pie-size: box-model(xs) * 20;

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.3);
    // Needs to be bigger than the one set for `.input-switch-player`
    z-index: 12;

    .content {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;

        h1 {
            color: color(base-000);
        }
    }

    .countdown {
        @include font-size(xxxl);
        @include line-height(xr);
        color: color(base-000);
        text-align: center;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }

    .pie-wrap {
        width: $pie-size;
        height: $pie-size;
        position: relative;
    }

    .slice-wrap {
        position: absolute;
        width: $pie-size;
        height: $pie-size;
        border-radius: 50%;
        clip: rect(0, $pie-size, $pie-size, math.div($pie-size, 2));
    }

    .slice-wrap:before {
        content: " ";
        position: absolute;
        left: 0;
        top: 0;
        width: $pie-size;
        height: $pie-size;
        border-radius: 50%;
        box-sizing: border-box;
        clip: rect(0, math.div($pie-size, 2), $pie-size, 0);
        background-color: rgba(255,255,255,0.5);
    }

    .second-half.slice-wrap {
        transform: rotate(.5turn);
    }

    .first-half:before {
        transform: var(--transformFirst);
    }
    .second-half:before {
        transform: var(--transformSecond);
    }
}
