@use "sass:math";

// Abstracts
@use "abstracts/index" as *;

input::-webkit-calendar-picker-indicator{
    display: none;
}

.inline-date-picker .mx-datepicker-main {
    border: 0;
}

.rd-container {
    box-shadow: $date-picker-box-shadow;
    border: 1px solid color(base-300);
    border-radius: $base-border-radius;
    padding: box-model(m, xs, 0);
    margin-top: box-model(xs);
    min-width: $date-picker-min-width;
}

.rd-back, .rd-next {
    height: box-model(l);
    width: box-model(l);
}

.rd-back {
    @include iconify(ic-chevron-left);
}

.rd-next {
    @include iconify(ic-chevron-right);
}

.rd-month-label {
    margin-bottom: box-model(m);
}

.rd-month {

    margin: box-model(0, xs, m);

    table {
        @include font-size(s);
    }

    table > thead > tr > th {
        text-align: center;
        background-color: transparent;
        text-transform: uppercase;
    }

    th, td {
        padding: 0;
        width: $date-picker-cell-size;
        line-height: $date-picker-cell-size;
    }

    th {
        @include font-size(xxs);
        @include font-weight(thin);
    }

    td {
        border-radius: $date-picker-cell-size;

        &:hover {
            background-color: color(primary-100);
        }
    }
}

.rd-day-selected {
    background-color: color(primary-300);
    color: color(base-000);
}

.rd-day-prev-month {
    color: color(base-300);
}

.rd-time {
    display: inline-block;
    margin-bottom: box-model(m);
    display: block;
    text-align: left;

    &:dir(rtl) {
        text-align: right;
    }
}

.rd-time-selected {
    @include icon(ic-chevron-down);
    @include line-height(s);

    padding: math.div(box-model(s), 2) box-model(s);
    position: relative;
    background-color: color(base-000);
    color: color(base-700);
    border: 1px solid color(base-500);
    border-radius: $base-border-radius;

    &::before {
        @include iconize;
        line-height: $input-height - box-model(xxs); // icon's specific
        display: block;

        position: absolute;
        top: 0;
        right: 0;
        border-radius: $base-border-radius;

        height: $input-height - 2px; // borders
        width: $input-height - 2px;
        background-color: color(base-000);

        text-align: center;
    }
}

.rd-time-list {
    border: 1px solid color(base-300);
    margin-top: box-model(xs);
    border-radius: $base-border-radius;
}

.rd-time-option {
    cursor: pointer;
    padding: math.div(box-model(s), 2) box-model(s);

    &:hover {
        background-color: color(primary-100);
        color: color(base-700);
    }
}

input[type='date'],
input[type='datetime-local'],
input[data-bs-datepicker],
.datepicker-trigger {
    @include icon(ic-calendar);

    cursor: pointer;
    position: relative;

    &::before {
        @include iconize;
        line-height: $input-height - box-model(xxs); // icon's specific
        display: block;

        position: absolute;
        top: 0;
        right: 0;
        border-radius: $base-border-radius;

        height: $input-height - 2px; // borders
        width: $input-height - 2px;
        background-color: color(base-000);

        text-align: center;
    }
}

.location-time-slots {
    .slots {
        > .form-group {
            margin-bottom: box-model(xs);
        }
    }

    .time-slots {
        padding-bottom: box-model(m);
        margin-bottom: box-model(xs);
        border-bottom: 1px solid color(base-300);

        > .btn {
            margin-left: box-model(xl) + box-model(xxs);
            margin-top: box-model(xs);
        }

        .feature-dependant {
            margin-top: box-model(xs);

            .form-group {
                margin-bottom: 0;
            }
        }
    }

    .range-actions {
        display: flex;
        align-items: center;
        gap: box-model(s);
    }
}
