// Abstracts
@use "abstracts/index" as *;

.stream-settings, .stream-settings-VOD {
    .resolution-control {
        .separator {
            padding-left: box-model(xxs);
            padding-right: box-model(xxs);
        }
        .hint, .separator {
            color: color(base-500);
        }
    }

    .expandable-content-group {
        border: 1px solid #e3e6e8;
        padding: 13px;
        border-radius: 8px;

        .expandable-content-header {
            display: flex;
            align-items: center;

            i {
                padding-right: box-model(xs);
            }
        }
        .main-label {
            font-size: 0.90rem;
        }

        .small-hint {
            max-width: 550px;
        }
    }

    .virtual-background-description {
        @include font-weight(normal);
        @include font-size(s);
        @include line-height(document);

        color: color(base-500);
        margin-left: box-model(xxl);
        margin-bottom: box-model(l);
    }
}

.stream-settings-VOD{
    display: flex;
    flex-direction: column;
    max-height: $modal-base-max-height;
    .header {
        padding: box-model(l) box-model(xxl);

        h2 {
            margin-bottom: 0;
        }
    }
    .body {
        padding: box-model(l) box-model(xxl);
        padding-top: 0;
        overflow: auto;
        flex: 1 1 auto;

        .interactivity-qna {
            .control-hint {
                margin-bottom: box-model(m);
            }
        }

        .form-group {
            margin-bottom: box-model(m);
        }

        .expandable.expandable-content-group {
            &.open {
                section {
                    padding-top: box-model(xs)
                }
            }
        }
    }

    .footer {
        border-top: 1px solid color(base-300);
        padding: box-model(l) box-model(xxl);
        display: flex;
        justify-content: space-between;
    }

    .save-button {
        margin-left: auto;
    }
}

.word-cloud-colours {

    .label-section, .form-group::before {
        transform: translateY(- box-model(s));
    }

    .colours-section .form-group {
        padding-left: initial;

        &::before { display: none }
    }

    .colours-section {
        width: box-model(xl) * 4;
        margin-right: box-model(m);
    }

    .color-block {
        display: flex;
        flex-wrap: wrap;
        .headlines {
            margin-bottom: box-model(xxs);
            padding-left: box-model(xs) + 2px;
        }
        small {
            padding-left: box-model(xs) + 2px;
        }
    }
}
