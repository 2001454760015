// Abstracts
@use "abstracts/index" as *;

.word-cloud-blocked-words-preferences {
    .enable-toggler.form-group {
        margin-bottom: calc(box-model(m) + box-model(xxs));
        label.toggler {
            margin-bottom: 0;
        }
    }
    .blocked-words-select {
        .control-label {
            display: flex;
            justify-content: space-between;
            .delete-button {
                color: color(error-300);
                &:hover {
                    color: color(error-305);
                }
            }
        }
    }
    .add-language-button {
        display: block;
        margin-left: auto;
        margin-bottom: calc(box-model(m) + box-model(xxs));
    }
    .save-button {
        margin-left: 0;
    }
}
