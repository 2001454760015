// Abstracts
@use "abstracts/index" as *;

.event-dashboard {
    > header {
        @include grid(12);
        margin-bottom: box-model(l);
    }

    h1 {
        @include cols(7);
        margin-bottom: 0;
    }

    .countdowns {
        @include cols(5);
        @include line-height(xm);

        text-align: right;

        p {
            margin-bottom: 0;
        }

        .archival-countdown {
            margin-left: box-model(xs);
            color: color(base-500);
        }
    }

    section.charts {
        margin-bottom: box-model(l);
    }

    .chart-area {
        border: 1px solid color(base-300);
        border-radius: box-model(xs);
        padding: box-model(m);
        box-shadow: box-model(0, xxxs, xxxs) colorAlpha(base-700, 0.04);
    }
}

