// Abstracts
@use "abstracts/index" as *;

.sidemenu {
    padding: box-model(l, m);
    border-radius: $base-border-radius;
    background-color: color(base-000);

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    li {
        &.selected a {
            background-color: color(base-100);
            color: color(primary-300);
        }

        a {
            display: block;
            color: color(base-700);
            line-height: line-height(base);
            padding: box-model(xs, m);
            border-radius: $base-border-radius;
            cursor: pointer;

            &:hover {
                background-color: color(base-100);
            }
        }
    }

    .counter {
        @include font-size(s);
        @include font-weight(semibold);
        @include line-height(xxs);
        background-color: color(primary-100);
        border-radius: $base-border-radius;
        color: color(primary-300);
        display: inline-block;
        float: right;
        margin-top: 3px;
        padding: 0 box-model(xs);
    }
}
