@use "sass:math";

// Abstracts
@use "abstracts/index" as *;

.stat-card {
    padding: box-model(xxs, xs);
    line-height: 1;

    .column {
        flex: auto;

        &.icon {
            align-self: center;
            text-align: center;
            font-size: 18px;
        }

        &.danger {
            color: color(error-300);
        }
    }

    .badge {
        margin: -2px 0 2px;
        vertical-align: top;
    }

    .title {
        @include font-size(s);

        color: color(base-600);
        line-height: 1;
        display: flex;
        align-items: center;

        &.bad {
            color: color(error-300);
            cursor: pointer;
        }
        &.weak {
            cursor: pointer;
            i {
                color: color(alert-300);
            }
        }

        i {
            margin-left: box-model(xxxs);
        }
    }

    .stats-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 0 box-model(s);
        width: 3 * box-model(xxl);
    }

    .bars {
        display: flex;
        align-items: flex-end;
        height: box-model(m);
        gap: box-model(xxxs);
        margin-bottom: box-model(xxxs);

        &.excellent {
            .bar {
                background-color: color(success-300);
            }
        }

        &.good {
            .bar:nth-child(3),
            .bar:nth-child(2),
            .bar:nth-child(1) {
                background-color: color(success-300);
            }
        }

        &.weak {
            .bar:nth-child(2),
            .bar:nth-child(1) {
                background-color: color(alert-300);
            }
        }

        &.bad {
            .bar:nth-child(1) {
                background-color: color(error-300);
            }
        }

        .bar {
            border-radius: $base-border-radius * 0.5;
            background-color: color(base-300);
            width: box-model(xxs);
            // following heights are hardcoded as we're simulating a network icon, cannot use multiple of 8 here.
            &:nth-child(1) {
                height: 6px;
            }
            &:nth-child(2) {
                height: 9px;
            }
            &:nth-child(3) {
                height: 12px;
            }
            &:nth-child(4) {
                height: 15px;
            }
        }
    }

    &.danger, &.warning {
        cursor: pointer;
        pointer-events: visible;
    }

    &.danger .stat{
        color: color(error-300);
    }

    &.warning .stat{
        color: color(warning-300);
    }

    .stat {
        @include font-size(base);
        @include line-height(xxs);
        @include font-weight(semibold);
    }
}
