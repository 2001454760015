// Abstracts
@use "abstracts/index" as *;

.session-registrations {
    border-bottom: 1px solid color(base-300);
    margin-bottom: box-model(m);
    padding-bottom: box-model(m);

    p {
        margin-bottom: 0;
    }
}
