// Abstracts
@use "abstracts/index" as *;

.affected-users {
    .search-bar {
        margin-bottom: box-model(m);
    }

    .table-wrapper {
        height: box-model(xs) * 50;
    }
}
