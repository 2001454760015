// Abstracts
@use "abstracts/index" as *;
@use "default/components/cards/base" as *;

.list-tile {
    @extend %card-with-borders;

    &:hover {
        background-color: color(base-100);

        .title {
            color: color(primary-300);
        }

        .badge.has-action {
            &.info {
                color: color(base-000);
                background-color: color(primary-300);
            }

            &.success {
                color: color(base-000);
                background-color: color(success-300);
            }
        }
    }

    &.selected,
    &.selected:hover {
        background-color: colorAlpha(primary-300, .1);
    }

    $media-size: 60px;
    $tagline-min-height: 36px;

    .title {
        color: color(base-700);
    }

    .tags {
        @include clearfix;
        line-height: 0;
        margin-bottom: 6px;
        text-align: right;
    }

    .contents {
        display: inline-block;
        height: $media-size;
        margin-bottom: box-model(m);
        width: $media-size;
        position: relative;
        text-align: center;

        img {
            height: $media-size;
        }

        i {
            color: color(base-700);
            font-size: $media-size;
            position: absolute;
            display: none;
            top: 0;
            left: 0;
        }

        img[lazy="error"] + i, img.empty + i {
            display: block;
        }
    }

    .title {
        @include font-size(document);
        margin-bottom: box-model(xs);
        transition: $base-text-transition;
    }

    .description {
        @include font-size(s);
        @include line-height(xxs);
        color: color(base-500);
        display: table;
        min-height: $tagline-min-height;
        width: 100%;

        span.text {
            display: table-cell;
            vertical-align: middle;
        }
    }
}
