// Abstracts
@use "abstracts/index" as *;

.image-cropper, .image-picker {
    .toolbar {
        text-align: right;
        margin: box-model(0, 0, xs);
        line-height: 1;
    }
}
