@use "sass:math";

// Abstracts
@use "abstracts/index" as *;

.live-stream-qna {
    $button-height: 40px;
    $add-question-input-size: 22px;

    border-top: 0;
    box-sizing: border-box;
    background-color: color(base-100);
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    .heading {
        flex: 1 1 auto;
        background-color: color(base-000);

        .title {
            padding: box-model(s, m);
        }

        .badge {
            float: right;
            margin: 3px 0 0;
        }
    }

    .qna-sorting-picker {
        // height: box-model(xl);
        background-color: color(base-000);
        margin-bottom: box-model(xs);
        display: flex;
        flex-direction: row;
        align-items: stretch;
        padding: box-model(xxs) box-model(xxs) box-model(xxs) box-model(xs);
        border-bottom: 1px solid color(base-300);
        overflow: visible;

        .orientation-toggler {
            display: flex;
            align-items: center;
            border-radius: box-model(xxs);
            padding: 0 box-model(xxs);
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            color: color(base-550);
            cursor: pointer;
            .orientation-label {
                margin-right: 4px;
            }
            &:hover {
                background-color: color(base-100);
                color: color(base-700);
            }
        }

        .label {
            flex: 1 1 auto;
            line-height: box-model(l);
            cursor: pointer;
            font-weight: 500;
            color: color(base-500);
        }

        .picker {
            line-height: box-model(l);
            cursor: pointer;
            text-align: right;
            display: flex;
            flex-direction: row;
            border-radius: box-model(xxs);
            padding: 0 box-model(xxs);
            margin-left: auto;

            &:hover, &.in {
                background-color: color(base-100);
            }

            .dropdown-label {
                display: inline-block;
                font-size: font-size(s);
                font-weight: 600;
            }

            i {
                display: inline-block;
                margin-left: box-model(xxs);
                transform: translateY(#{box-model(xxs)}) rotate(90deg);

                &:before {
                    font-weight: 100;
                    font-size: font-size(m);
                }
            }

            &.in i { transform: translateY(#{box-model(xxs)}) rotate(-90deg); }
        }

        .menu {
            transform: translateY(#{box-model(l)});
            min-width: unset;

            .item {
                padding: 0 box-model(s) 0 box-model(xxs);
                white-space: nowrap;
                font-size: font-size(s);
                line-height: line-height(m);
                font-weight: 600;
                color: color(base-600);

                &.active {
                    color: color(primary-300);
                }
            }
        }
    }

    .tabs-menu {
        @include font-size(s);
        display: flex;
        border-bottom: 1px solid color(base-300);

        .item {
            position: relative;
            flex: 1 1 33%;
            background-color: color(base-000);
            text-align: center;
            cursor: pointer;
            border-left: 1px solid color(base-300);
            border-right: 1px solid color(base-300);
            margin: 0 -1px; // borders size
            padding: box-model(xs, xxs);

            &.active {
                box-shadow: inset 0px (box-model(xxxs) * -1) 0px color(primary-300);
                background-color: color(primary-100);
                color: color(primary-300);
            }

            i, span {
                display: block;
            }
            i {
                @include font-size(s);
            }

            .notification-dot {
                position: absolute;
                top: box-model(xxs);
                right: box-model(s);
                width: box-model(xs);
                height: box-model(xs);

                border-radius: 50%;
                color: color(base-000);
                background-color: color(error-300);
            }
        }
    }

    .add-question {
        flex: 1 1 auto;
        background-color: color(base-000);
        position: relative;
        border-top: 1px solid color(base-300);

        .question-text {
            margin: 0;
            padding: 0;

            input {
                border: none;
                box-shadow: none;
                outline: none;
                margin: 0;
                height: $add-question-input-size;
                line-height: $add-question-input-size;
            }
        }
    }

    .questions {
        flex: 1 1 100%;
        overflow: auto;
    }

    .actions {
        @include clearfix;

        padding: box-model(xs);
        margin: 0;
    }

    .empty-questions {
        padding: box-model(xl);
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
        flex: 1 1 100%;
        overflow: auto;

        .icon {
            padding-bottom: box-model(xs);
            font-size: icon-size(xs);
        }
    }
}

.live-stream-qna-question {
    $edit-question-button-size: 20px;
    $edit-question-button-side-margin: box-model(xs);

    background-color: color(base-000);
    margin-top: 0;
    margin-bottom: box-model(m);
    border-top: 1px solid color(base-300);
    padding: 0;
    position: relative;


    &.no-buttons {
        border-bottom: 1px solid color(base-300);
    }

    &.highlighted.no-buttons {
        border: 1px solid color(primary-300);
        border-radius: 4px;
        background-color: color(primary-100);
    }

    .loading {
        left: 50%;
    }

    .top-bar {
        margin-right: $edit-question-button-size + 2 * $edit-question-button-side-margin;

        &.no-buttons {
            margin-right: box-model(xs);
        }
    }

    .upvotes-container {
        position: relative;
        top: #{box-model(xxxs) * 3};
        margin-left: box-model(xs);
        font-weight: 600;
        line-height: line-height(xs);

        i {
            color: color(base-500);
            margin-right: box-model(xxs);
        }
    }

    .badge-container {
        padding: 0;

        .badge {
            position: relative;
            top: box-model(xxs);
            margin-right: 0px;
        }
    }

    .answering-now {
        @include font-weight(semibold);
        padding-left: box-model(xs);
        color: color(primary-300);
        padding-top: box-model(xs);
        text-transform: uppercase;
    }

    .info {
        @include font-size(s);
        @include line-height(document);

        margin-top: box-model(xs);
        margin-left: box-model(xs);
        display: flex;
        flex-wrap: wrap;
    }

    .user-name {
        @include font-weight(semibold);

        color: color(base-700);
        margin-right: box-model(xxs);
    }

    .timestamp {
        color: color(base-500);
    }

    .edit-button,
    .dropdown {
        padding: 0;
        cursor: pointer;
        position: absolute;
        top: box-model(xs);
        right: $edit-question-button-side-margin;

        i {
            margin: 0;
            padding: 0;
            width: $edit-question-button-size;
            height: $edit-question-button-size;
            background-color: color(base-100);
            text-align: center;
            line-height: $edit-question-button-size;
            display: block;
            box-sizing: border-box;
            border-radius: math.div($base-border-radius, 2);
        }

        .activator button.default {
            padding: 0;
            background-color: color(base-100);
            width: $edit-question-button-size;

            i {
                @include icon-size(xxs);
                line-height: normal;
            }
        }
    }

    .dropdown {
        .menu {
            min-width: max-content;
            margin-top: 0px;
        }

        li {
            display: flex;
            align-items: center;
            gap: box-model(xxs);
            padding: box-model(xxs) box-model(xs);

            i {
                padding-right: box-model(xxs);
                background-color: transparent;
            }
        }
    }

    .body {
        @include line-height(s);
        max-height: box-model(h) * 2;
        overflow: auto;
        overflow-wrap: break-word;
        margin-top: box-model(xxs);
        margin-bottom: box-model(xs);
        margin-left: box-model(xs);
        margin-right: box-model(xs);
    }

    .buttons {
        margin: 0;
        display: flex;
        overflow: hidden;
    }

    .button {
        background-color: color(base-000);
        flex: 1 1 50%;
        text-align: center;
        color: color(base-600);
        cursor: pointer;
        border: 1px solid color(base-300);
        margin: 0 -1px;
        padding: box-model(xxs, xs);
        line-height: 1.5em;

        &:active {
            background-color: color(base-100);
        }

        &:first-child {
            box-shadow: inset 1px 0px 0px color(base-100);
        }

        &.approve-button, &.push-to-screen-button, &.hide-from-screen-button{
            color: color(primary-300);
        }

        &.move-to-answered{
            @include font-size(document);
            background-color: color(primary-300);
            color: color(base-000);
            flex: box-model(xxl) + 4;
            height: box-model(xl);

            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                background-color: color(primary-305);
            }
        }
    }

    a.link {
        word-break: break-all;
        overflow-wrap: anywhere;

        &.link-url.disabled {
            color: color(base-700);
            pointer-events: none;
        }
    }

    button:has(> a.link-url.disabled) {
        cursor: not-allowed;
    }
}

.question-form {
    textarea {
        resize: none;
        height: 100px;
    }
}

@media (hover: hover) {
    .live-stream-qna-question {
        .button:hover {
            background-color: color(base-100);
        }
    }
}
