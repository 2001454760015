// Abstracts
@use "abstracts/index" as *;

img[lazy], i[lazy], .lazy {
    transition: $base-opacity-transition;
    opacity: 0;
}

img[lazy='loaded'], i[lazy='loaded'] {
    opacity: 1;
}

