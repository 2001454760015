// Abstracts
@use "abstracts/index" as *;

.bg-export-fp-type, .bg-export-fp-type .bg-export {
    display: inline-block !important;
    width: auto !important;
}

.bg-export-fp-type .bg-export {
    margin-left: 4px !important;
    margin-right: 4px !important;
}
