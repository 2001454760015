// Abstracts
@use "abstracts/index" as *;

.document-editor {
    .panels.row-fluid {
        margin: 0;
    }

    .package-inspector-title {
        position: static;
    }

    $editor-distance-from-top: 220px;

    document-editor #info {
        height: calc(100vh - #{$editor-distance-from-top});
    }
}
