@use "sass:math";

// Abstracts
@use "abstracts/index" as *;
@use "default/components/cards/base" as *;
@use "default/components/dropdowns" as *;
@use "default/components/forms" as *;
@use "default/components/badges" as *;
// Seek and destroy all this elements

.lte-ie9-fallback, .lte-ie8-fallback {
    display: none;
}


.input-block-level {
    display: block;
}

// Utility class used by packages that bring some custom styles.
.invisible-until-custom-styles-loaded {
    visibility: hidden;
}

.upload-custom-kind {
    visibility: visible;
}

// Edit preferences navigation menus
.module-preferences-view .filter-input-box {
    position: relative;
    margin-bottom: box-model(l);

    .clear-filter-button {
        color: color(base-500);
        position: absolute;
        top: 0;
        right: 0;
        padding: box-model(xs);
        line-height: 1;
        &:hover {
            text-decoration: none;
        }
    }
}
//event settings, align button to the right
.event-settings-manager-view {
    .create-template-button {
        text-align: right;
    }
    .event-settings-traits {
        width: 100%;
        margin-top: box-model(l);
        margin-bottom: box-model(l);
    }
}


// * External pickers HTML needs to be rewritten
// * Form generator HTML needs to be rewritten
.untableize,
*[bs-form-generator],
bs-legal-doc-editor,
.externals-table-canvas,
*[data-bs-form-generator],
.table-event-settings,
.form-generator-table-imitation {
    &, table {

        &.form-generator-array-field-container {
            margin-bottom: 0;

            & + .btn {
                margin: 0;
            }
        }

        &, thead, tbody, tfoot, tr, th, td {
            display: block;
        }

        > thead > tr > th,
        > thead > tr > td {
            background-color: transparent;
        }

        > tbody > tr {
            margin-bottom: box-model(l);
        }

        > tbody > tr > th {
            @include font-size(s);
            @include font-weight(semibold);
            @include line-height(xxs);
            margin-bottom: box-model(xs);
            text-align: left;

            &:dir(rtl) {
                text-align: right;
            }
        }

        th,
        td {
            padding: 0;
        }

        .md-html-form-container {
            @include clearfix;
        }
    }

    .select2-container.btn-add-item {
        height: auto !important;
        width: 100% !important;
    }

    .field-heading-row {
        @include clearfix;
    }

    .block-level-item {
        @include line-height(base);
        padding: box-model(xs) math.div(box-model(s), 2);
        border-bottom: 1px solid color(base-500);
        position: relative;
        display: flex;

        &:last-child {
            border: 0;
        }

        > i:first-of-type, .remove-link {
            flex: 0 0 icon-size(s);
        }

        > span, a.block-level-item-label {
            @include ellipsis(false);
            flex: 1;
            align-self: center;
        }

        > i.reorder-handle {
            order: -1;
            height: icon-size(s);
            width: icon-size(s);
        }

        .remove-link, i.reorder-handle {
            text-align: center;
        }

        .remove-link {
            margin-right: - box-model(m);

            .icon-remove-sign {
                &::before {
                    content: '\00d7';
                }
            }
        }

        i {
            vertical-align: middle !important;
            text-align: center;

            &::before {
                line-height: icon-size(s);
            }

            &.reorder-handle {
                left: 0;
                margin: 0 0 0 box-model(m) * -1;
                opacity: 1;
            }
        }
    }
}

.field-box aside i {
    margin-right: box-model(xs);
}


.externals-table-canvas {
    position: relative;

    .field-footer-row {
        .btn-group {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    .field-heading-row,
    .field-footer-row {
        .btn-group {
            margin-right: 0;
        }
    }
}

// Example of this ugly stuff: badges & certificate edit text dropdownmenu
.open {
    .dropdown-toggle + .dropdown-menu {
        @extend %opaque-forecomponent;
    }
}

.translucent {
    opacity: .5;
}

.strikethrough {
    text-decoration: line-through;
}

// Targeting tool
.externals-table-canvas {
    td.title {
        @include font-size(s);
        padding: 0 0 box-model(xs);
    }

    .targets-exceptions-tag-canvas {
        margin-bottom: box-model(m);

        .btn.btn-mini {
            margin: 0;
        }
    }

    .condition-line {
        @include line-height(s);
        margin: 0;
    }

    .no-rules-present-display-text {
        margin-right: box-model(m);
    }

    .affected {
        @include font-size(s);
    }

    .label {
        border-radius: $base-border-radius;
        padding: box-model(xs, s);
        margin-bottom: box-model(xs);
        cursor: pointer;
    }

    .label-success {
        border: 1px solid color(success-300);
    }

    .label-important {
        border: 1px solid color(error-300);
    }

    .targeted-pax-container {
        table {
            margin-top: box-model(xs);

            tr {
                margin-bottom: 0;

                > td {
                    padding: box-model(xxs);
                }
            }
        }
    }
}

.sso-config {
    .actions {
        float: right;
    }
}

.rd-container-attachment {
    z-index: z-index(modal);
}

/* Metadata filter rules */
.bt-metadata-rule-new-row {
    border: 0 !important;
    padding: 0 !important;
    margin: box-model(m, 0);

    &::after {
        display: none !important;
    }
}

.bt-metadata-ruleset {
    padding: 0 !important;
    border: 0 !important;

    &::before {
        display: none !important;
    }

    .form-groups {
        position: relative;
        margin-left: - box-model(xs);
        margin-right: - box-model(xs);

        .form-group {
            padding-left: box-model(xs);
            padding-right: box-model(xs);
            margin-bottom: 0;
        }
    }

}

.bt-metadata-rule {
    padding: 0 !important;
    margin-bottom: box-model(xs);

    .control-label {
        display: inline-flex;
        flex: 1 1 50%;
        margin-bottom: 0;
        width: 59%;

        &::before {
            display: none !important;
        }

        select {
            margin-right: box-model(xs);
        }
    }

    .controls {
        flex: 1 1 50%;
        display: inline-block;
        width: 40%;
        margin-left: box-model(xxs);
        vertical-align: bottom;

        .bt-metadata-rule-form .table, .bt-metadata-rule-form .table.table-plain {
            margin: 0 !important;

            tr {
                margin-bottom: 0 !important;
            }
        }
    }

    .close {
        opacity: 1 !important;
        right: - box-model(m);
        margin-top: 0;
        margin-right: - box-model(xs);
    }
}

.bt-metadata-rule-inserter {
    opacity: 1 !important;
    position: absolute;
    left: - box-model(l);
    top: 0;
    display: block;
    width: box-model(xl) !important;
    height: box-model(xl);
    text-align: center;
    line-height: box-model(xl);
    cursor: pointer;

    &::after {
        display: none !important;
    }
}

/**
 * Temporary file - to be removed as part of phase 3
 */
 table {
    th,
    td {
        &[class^='span'],
        &[class*=' span'] {
            width: auto !important;
            float: none;
            margin: 0;
        }
    }
}

h3 + hr {
    display: none;
}

.nav-list > li.nav-header > i {
    display: none;
}

.nav-list-wrapper .btn-mini {
    background-color: color(base-700);
    color: color(base-000);

    float: right;
    @include line-height(base);

    margin: 0;

    &:hover {
        background-color: color(base-600);
    }
}

.modal {
    // @see src/angular/directives/directives/strapModal.js hideBackdrop function
    &.fade,
    &.hide {
        display: none;
    }

    &.org-package-manager-modal {
        width: $modal-width-small;
        margin: 0 0 0 math.div($modal-width-small, -2);
    }

    .hidden-modal {
        display: none !important;
    }
}

.v--modal-box footer.actions > .btn {
    &:last-child {
        margin-right: 0;
    }
}

/**
  * Help item ideally to be rendered as a button (and out of the navs)
  */
.nav .nav-item.help-item {
    display: none;
}

// TODO: add the proper class where needed.
// WARN: Ask the CSS keeper before even think to touch the following
input[type='text'],
input[type='email'],
input[type='password'],
input[type='date'],
input[type='search'],
input[type='number'],
input[type='url'],
textarea,
select {
    &:not(.form-control) {
        @extend %form-control;
    }
}

.action-control-group {
    select {
        margin-bottom: box-model(xs);
    }
}

// TODO: Create a more generic management for global alerts
.entitlement-over-usage-notice {
    border-radius: 0;
    margin: 0;

    // TODO: H4 for a message!? Change this element with a P
    h4 {
        @include font-size(base);
        @include font-weight(semibold);
        @include line-height(base);
        margin: 0;
        border: 0;
    }
}

// Pushes "+" button for "list" fields
.form-generator-array-field-container {
    margin-bottom: box-model(m);

    // Draggable / reorder-able field items within "list" fields
    div.reorder-handle > input {
        cursor: move;
    }
}

.strap-tooltip {
    color: color(base-700);

    &:hover {
        color: inherit;
    }
}

// Relax unneeded floating elements
.pull-right {
    &.btn,
    &.form-actions,
    &.actions {
        float: none;
    }
}

table > tbody td label.text {
    margin-bottom: box-model(xs);
}

// Excel importer
.data-import-upload-view {
    .alert + input[type='file'] {
        position: static !important;
    }
}

.excel-import-wizard-container {
    hr {
        display: none;
    }

    .smart-table-outer-container {
        position: relative;
    }

    th {
        @include line-height(xxm);

        .btn {
            float: right;
        }

        label {
            @include font-weight(semibold);

            i {
                margin-left: box-model(xs);
            }
        }
    }
}

.org-package-manager {
    table tbody td {
        white-space: nowrap;
    }

    .search-bar i {
        @include icon(ic-search);
        z-index: 1;
    }
}

.org-package-manager-modal {
    .form-generator-array-field-container {
        select {
            width: 100% !important;
        }
    }
}

// todo: I have the impression that these styles could/should be applied elsewhere ?
*[data-bs-form-generator],
*[bs-form-generator]  {
    .form-generator-category-heading th {
        @include font-size(base);
        padding: box-model(xs, m);
        line-height: box-model(l);
        background-color: color(base-100);
        font-weight: 600;
        margin-bottom: 0;
        cursor: pointer;

        i {
            margin-top: box-model(xxs);
        }
    }

    th.form-generator-field-label {
        position: relative;
        z-index: 2;

        i {
            vertical-align: middle;
            margin-right: box-model(xs);
        }
    }
}

.create-user {
    @include grid(12);

    .inner-page {
        @include cols(6, 4);
    }

    .actions {
        text-align: right;
    }
}

.create-user-view-item {
    .grouped-controls {
        margin-bottom: box-model(xs);
    }

    .role {
        flex-basis: 20%;
    }

    .btn-group {
        margin-left: 0;
    }
}

.branded-app-configuration-view {
    .activation-pages-wrapper {
        margin-bottom: box-model(l);
    }

    .form-generator-field-label {
        text-align: left;
        width: 200px;
    }

    label.checkbox {
        input[type='checkbox'] {
            display: inline-block;
            margin-right: box-model(xxs);
        }
    }

    .pull-right.btn {
        float: right;
    }

    legend {
        padding: box-model(m, 0);
    }

    .app-branding-editor-section-app-theme {
        th,
        td {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .app-branding-editor-section-activation-screen .activation-page-box {
        @include line-height(base);
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
}

.app-brandings-list {
    .search-filter {
        span {
            @include font-size(s);
            margin-top: 0;
        }
    }
}

.support-page {
    .arrow {
        display: none;
    }
    ul {
        list-style: none;
        &.with-list {
            list-style: disc;
        }
    }
    form,
    h3,
    .btn {
        margin-top: box-model(m);
    }
}

.org-package-manager {
    .search-bar {
        display: block;
    }

    table {
        td {
            white-space: nowrap;
        }
    }

    .latest-version {
        display: inline;
    }

    .badge-new-version {
        @extend .badge-warn;
    }

    .badge-deprecated {
        @extend .badge-danger;
    }
}

.event-type-selection-step-view--intro-uberbox {
    background: white;
    color: color(base-700);
    h3 {
        color: color(base-700);
    }
    ul li.tab {
        background-color: color(base-000);
        color: color(base-700);
        border-radius: 4px;
        &.active {
            color: white;
            background-color: color(primary-300);
        }
        &:hover{
            color: white;
        }
    }
}
.event-type-selection-step-view--content {
    h2,
    h3,
    div,
    form.event-type-selection-step-view--proceed-form {
        margin-top: box-model(xl);
    }
    h3 {
        margin-bottom: box-model(xl);
    }
}
.modal-service {
    .modal-header {
        .spotlet-loading-data-throbber {
            display: none;
        }
    }
    .progress-striped .bar {
        background-color: color(primary-300);
    }
}
.form-generator-button-bar {
    text-align: right;
}
.language-bar {
    text-align: right;
    margin-bottom: box-model(l);

    .btn {
        margin-right: 0;
    }
}

.fullscreen-controls-container .tooltip {
    margin-left: -88px;
    margin-top: 24px;
}

.well {
    hr {
        background: transparent;
        border: 0;
        height: box-model(m);
    }
}

.accordion-group {
    @extend %card-with-borders;

    .accordion-heading {
        @include font-weight(semibold);
        padding: box-model(s ,m);
        border-bottom: 1px solid color(base-300);
    }

    .accordion-body {
        padding: box-model(m);

        ul {
            list-style-type: none;
            padding: 0;
        }
    }

}

.link-type-selector,
.asset-type-selector,
.modal.default-external-combo-widget-modal,
.nav-spotman-editor-view {
    .kind-external-field > .form-generator-field-label,
    .kind-custom-field > .form-generator-field-label {
        display: none;
    }
}

// should be wrapped in `fieldset` tag
// but multiple packages wrap this in in `div.inline-editable-fieldset`
.inline-editable-fieldset legend {
    @extend %h3;
    display: block;
}

.modal-targets-exceptions-rule-editor-v3 {
    .form-generator-field-value {
        display: inline-block;
    }
    .modal-body {
        .ph-form-generator {
            margin-bottom: 24px;
            border-bottom: 1px solid #E3E6E8;
        }
    }
    .remove{
        color: color(error-300);
    }
}

.form-control.ng-mdhtmlform-html, .form-control.ng-mdhtmlform-md {
    resize: vertical;
}

.dashboard-message > h2 {
    text-align: center;
    padding-top: 100px;
    border: none;
}

.qe-toolbar + .ql-container .ql-editor {
    min-height: 24px;
    padding: 4px;
    line-height: 18px;
}

.fsa-frontstage-architect {
    .form-generator-row.kind-custom-field .choice-options {
        overflow: unset;
    }
}

.bt-metadata-rule-divider {
    padding-top: box-model(xxs) !important;
    text-align: center;

    &.or {
        padding-top: 0 !important;
        margin: box-model(m, 0);
    }
}

.bt-metadata-rule-comparator {
    margin: 0 !important;
}

tr.field-heading-row > td > .btn-group {
    float: right;
}

.create-fptype {
    .kind-custom-field .form-generator-field-label {
        display: none;
    }

    div[data-bs-default-external-combo-widget="data-bs-default-external-combo-widget"] {
        .field-heading-row {
            display: none;
        }
    }
}

.dashboard-instruments-refresh-button-wrapper {
    float: left;

    button.refresh.btn {
        margin-right: box-model(xs);
        padding-left: 0;
        padding-right: 0;
        width: icon-size(s);
    }
}

.custom-kind-config {
    margin-top: box-model(xs);
}

.custom-kind-selector {
    display: flex;
    margin-bottom: box-model(xs);
}

.custom-kind-actions {
    text-align: right;
    margin-bottom: box-model(m);
}

.custom-kind-settings {
    padding: box-model(m);
    background-color: color(primary-100);
    border-radius: $base-border-radius;
}

.webapp-login-link-modal {
    .text-center {
        text-align: center;
    }

    .loading {
        padding: 65px 0;
    }

    .link-details {
        padding: 15px 0 0 0;
    }

    textarea {
        font-family: monospace;
        font-size: inherit;
        white-space: pre-wrap;
        word-break: break-all;
        word-wrap: break-word;
    }
}

.meta-form .field-heading-row {
    @include font-size(s);
    @include font-weight(semibold);
    @include line-height(xxs);
    margin-bottom: box-size(xs);
    display: block;
}

.metadata-manager-view {
    .btn-save-changes {
        float: right;
    }
}


.content-wrapper.messages, .content-wrapper.presenter {
    .smart-table-data-cell {
        &.body, &.sender,
        &.fullname, &.title {
            white-space: nowrap;
            position: relative;
            width: 35%;

            > * {
                @include ellipsis;
                position: absolute;
                top: box-model(xs);
                bottom: box-model(xs);
                left: box-model(s);
                right: box-model(s);
            }
        }
    }
}

.content-wrapper.presenter {
    .smart-table-data-cell {
        &.fullname {
            width: auto;
        }
    }
}

.content-wrapper.presenter .smart-table-data-cell._id,
#list-presenter .smart-table-data-cell.photo {
    font-size: 0;
    line-height: 0;
    width: box-model(xxxl);

    img {
        height: box-model(xxl);
        width: box-model(xxl);
        border-radius: 100%;
        object-fit: cover;
    }
}

td.smart-table-data-cell.selector {
    width: box-model(s);
}

.bt-metadata-ruleset .bt-metadata-rule-form .select2-container.btn-add-item {
    margin: 0;
}

.content-wrapper.apps:has(.branded-app-legal) {
    .ng-view.ng-scope {
        .bs-common-toolbar {
            display: none;
        }
    }
}

.bs-common-toolbar {
    .buttons-container {
        text-align: right;
    }
}
