@use "sass:meta";

// Abstracts
@use "abstracts/index" as *;

// ===========================
// Defaults and common devices
// ===========================

@use "default/base";
@use "default/dark-mode";

// ===========
// Extra Large
// ===========

// Extra large up
@media (min-width: $screen-xl-min) {
    @include meta.load-css("extra-large/base");
}

// =====
// Large
// =====

// Large up
@media (min-width: $screen-lg-min) {
    @include meta.load-css("large-up/base");
}

// Large only
@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
    @include meta.load-css("large/base")
}

// Large down
@media (max-width: $screen-lg-max) {
    @include meta.load-css("large-down/base");
}

// ===========
// Middle size
// ===========

// Mid up
@media (min-width: $screen-md-min) {
    @include meta.load-css("mid-up/base");
}

// Mid only
@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    @include meta.load-css("mid/base");
}

// Mid down
@media (max-width: $screen-md-max) {
    @include meta.load-css("mid-down/base");
}

// ==========
// Small size
// ==========

// Small up
@media (min-width: $screen-sm-min) {
    @include meta.load-css("small-up/base");
}

// Small only
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    @include meta.load-css("small/base");
}

// Small down
@media (max-width: $screen-sm-max) {
    @include meta.load-css("small-down/base");
}

// ===========
// Extra small
// ===========

// Extra small only
@media (max-width: $screen-xs-max) {
    @include meta.load-css("extra-small/base");
}

// ========
// Printers
// ========

// All printers
@include meta.load-css("print/base");
