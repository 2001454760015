// Abstracts
@use "abstracts/index" as *;
@use "default/components/buttons" as *;

.badger-wizard {
    .wizard-actions {
        button {
            @extend .btn;
        }
    }

    .dropdown-toggle {
        border-radius: $base-border-radius;
    }

    .badger-template-designer {
        .btn-toolbar {
            padding: box-model(xxs) !important;

            input {
                margin: 0 !important;
            }

            select {
                height: $input-height !important;
            }
        }
    }
}

