@use "sass:math";

// Abstracts
@use "abstracts/index" as *;

.live-stream-qna-wrapper,
.live-stream-polling-wrapper {
    height: 100%;
    overflow: auto;
    background-color: color(base-100);

    .live-stream-qna.disabled,
    .live-stream-polling.disabled {

        align-items: center;
        justify-content: center;

        .content {
            display: flex;
            flex-direction: column;
            align-items: center;

            width: 192px;
            text-align: center;

            .icon {
                padding-bottom: box-model(xs);
            }
            i {
                font-size: icon-size(xs);
            }
        }
        button {
            margin-top: box-model(m);
        }
    }
}

.live-session-room {

    &.streamless {
        .tabs {
            .item {
                border: 1px solid color(base-300);
            }
        }

        .show-results-live .toggler.inline-tooltip {
            i {
                margin-top: box-model(xxxs);
            }
        }
    }

    &.has-loader {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 100vh;
        .loader {
            margin: 0 auto;
        }
    }

    .state {
        margin-top: -3px;
        vertical-align: middle;
    }

    .reactions {
        overflow: hidden;
    }

    .loader {
        height: auto;
        margin-bottom: box-model(xxl);
        margin-top: 0;
    }

    .controls {
        .countdown {
            @include line-height(xxm);

            margin-right: box-model(xs);
            width: box-model(xs) * 7;
        }
    }

    .with-countdown {
        flex-wrap: wrap;
    }

    .video-placeholder {
        position: relative;
        background-color: color(base-700);
        color: color(base-000);
        overflow: hidden;

        &::before {
            content: '';
            display: block;
            height: 0;
            width: 0;
            padding-bottom: percentage(math.div(9, 16));
        }

        .content {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            transform: translateY(-50%);
            text-align: center;

            button{
                + p {
                    margin-top: box-model(l);
                    margin-bottom: (box-model(l) + line-height-original(base)) * -1;
                }
            }
        }
    }

    .outer-wrapper {
        $margin-from-bottom: box-model(xl);
        display: flex;

        .inner-wrapper{
            flex: 1 1 auto;
        }

        .video-panel {
            background-color: color(base-100);
            flex-grow: 1;
            // justify-content: center;
            // text-align: center;

            .live-stream-videos {
                height: 100%;
            }

            .videos-list {
                padding-left: 0px;
                padding-right: 0px;
                overflow-x: hidden;
                overflow-y: scroll;
            }
        }

        .inner-wrapper.has-sidebar .setup {
            margin-left: box-model(s);
        }
    }

    .streaming {
        position: relative;

        .video-stream {
            text-align: center;

            &.vod {
                display: flex;
                flex-direction: column;
                height: 100%;
            }
        }
    }

    .setup {
        margin-top: box-model(l);
        padding-right: box-model(m);
    }

    .endpoints {
        .input-urls {
            display: flex;
            justify-content: space-between;
        }

        .base-url {
            flex: 1;
            margin-bottom: 0;
            border-radius: $base-border-radius 0px 0px $base-border-radius;
            -webkit-user-select: none;
            -moz-user-select: none;
            user-select: none;
        }

        .btn {
            border-radius: 0px $base-border-radius $base-border-radius 0px;
            display: flex;
            padding: box-model(m);
            i {
                margin: 0;
            }
        }

        .input-box {
            display: flex;
            justify-content: center;

            .btn-primary {
                margin-bottom: 0;
                align-items: center;
            }
        }

        .push-url {
            margin-right: box-model(xs);
        }

        .stream-key {
            margin-left: box-model(xs);
        }

        .push-url,
        .stream-key {
            display: block;
            flex: 1;
            box-sizing: border-box;
        }
    }

    .caption-button {
        margin-top: box-model(s);
        margin-left: 0;
    }

    .sidebar {
        display: flex;
        flex-direction: column;
        // accounting for omission of the collapse bar
        width: #{$live-stream-sidebar-width - box-model(xxxl)};
        min-width: #{$live-stream-sidebar-width - box-model(xxxl)};
        max-width: #{$live-stream-sidebar-width - box-model(xxxl)};

        &.video-panel {
            border: 1px solid color(base-300);
        }

        .tabs {
            border-right: 1px solid color(base-300);
        }
    }

    .badge.live {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        margin-top: box-model(xs);
        color: color(base-000);
        background-color: color(error-300);
    }
}

.live-stream-form {
    margin-bottom: box-model(l);

    &.panels {
        margin-bottom: box-model(xl);
        padding-bottom: box-model(xl);
    }
    > .panel {
        min-width: map-get($global-sizes, m) * 2 + 194px * 3;
    }

    .third-party-inputs {
        margin-bottom: box-model(m);
    }

    .form-group.with-border {
        border: 1px solid color(base-300);
        padding: 13px;
        border-radius: 8px;

        .main-label {
            font-size: 0.90rem;
        }

        .small-hint {
            max-width: 550px;
        }
    }

    .outer-wrapper {
        height: unset;
    }

    .captions-options {
        margin-top: 0px;
        margin-left: box-model(xxl);

        .control-hint {
            margin-bottom: box-model(s);
        }

        .captions-services {
            padding-left: 0px;

            &::before {
                display: none;
            }
        }
    }
}

.btn.finish {
    color: white;
}

.third-party-stream .streaming {
    background-color: color(base-700);
    color: color(base-000);
    overflow: hidden;
}

.spotme-live-stream {
    margin-bottom: box-model(l);

    .stream {
        position: relative;
        background-color: color(base-700);
        color: color(base-000);
        border-radius: $base-border-radius;
        overflow: hidden;
    }
}

.session-type {
    padding-bottom: box-model(l);
}

.session-type-buttons {
    display: flex;
    gap: box-model(m);

    button {
        width: 194px;
        height: 96px;
        padding: box-model(s);
        border: 1px solid color(base-300);
        background-color: color(base-000);
        color: color(base-700);
        border-radius: 8px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
        cursor: pointer;

        .icon {
            font-size: icon-size(xs);
        }
        .title {
            margin-bottom: 0px;
            color: color(base-700);
            font-weight: 500;
            font-size: 14px;
        }
        .description {
            margin-bottom: 0px;
            font-size: 10px;
            color: color(base-600);
        }
    }
    button.active {
        color: color(primary-300);
        border-color: color(primary-300);
        background-color: color(primary-050);

        .title {
            color: color(primary-300);
        }
    }
}
.session-type-badge {
    padding: box-model(xs) box-model(m);
    display: flex;
    border: 1px solid color(base-300);
    border-radius: 4px;
    width: 106px;
    height: 24px;

    .icon {
        font-size: icon-size(xs);
        color: color(primary-300);
    }
    .title {
        margin-left: box-model(s);
        color: color(base-700);
        font-weight: 500;
        font-size: 14px;
    }
}

.new-question-button-wrapper {
    background-color: green;
    position: relative;
    bottom: 60px;
    right: 0;
    width: 100%;
    height: 0;
    display: flex;
    justify-content: center;

    button {
        background-color: color(base-300);
        height: box-model(xl);
        display: flex;
        align-items: center;
        cursor: pointer;
    }
}
