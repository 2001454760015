// Abstracts
@use "abstracts/index" as *;

.targeting-control {
    > .form-control {
        padding: box-model(xxs, 0, xxs, s);
    }

    .expandable {
        &.open > section {
            padding: box-model(xs, s, m);
        }

        &.slim {
            header {
                padding: 0;
            }

            label {
                @include line-height(s);
            }

            &.open > section {
                padding: box-model(s, 0);
            }
        }
    }

    label {
        @include line-height(base);

        cursor: pointer;
        margin: 0;
    }

    fieldset:last-of-type {
        border-bottom: 1px solid color(base-300);
    }

    .visible-to {
        margin-bottom: 0;

        legend {
            color: color(success-500);
        }
    }

    .hidden-to legend {
        color: color(error-500);
    }

    legend {
        @include line-height(s);
        @include font-weight(semibold);
        @include font-size(s);

        border: 0;
        margin: 0;

        i {
            vertical-align: sub;
            margin-right: box-model(xs);
        }
    }

    p.description {
        color: color(base-600);
        margin-bottom: box-model(xs);
    }

    .rules {
        padding: box-model(xs, 0);
    }

    .edit, .remove {
        display: none;
    }

    .targeting-errors {
        color: color(error-500);
    }

    .affected-users {
        i {
            @include font-size(s);
            vertical-align: middle;
            margin-left: box-model(xs);
        }
    }
}

.meta-form {
    .targeting-control {
        > .form-control {
            padding: 0;
        }

        .expandable {
            > header {
                label {
                    @include font-size(base);
                }
            }
        }
    }
}

.person-target {
    &.inline {
        display: flex;
        column-gap: box-model(xs);
    }

    legend {
        @include line-height(xxm);
        @include font-weight(semibold);
        @include font-size(s);

        border: 0;
        margin: 0;
        color: color(success-500);
        white-space: nowrap;
    }

    .actions {
        @include line-height(xxm);
    }
}
