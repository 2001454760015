@use "sass:math";

// Abstracts
@use "abstracts/index" as *;

.progress {
    background-color: color(base-100);
    border-radius: $base-border-radius;
    height: $progress-bar-height;
    margin-top: box-model(m);
    position: relative;

    &.secondary {
        margin: box-model(xs, 0);
        background-color: colorAlpha(base-700, .3);

        .progress-bar {
            background-color: color(base-000);
        }
    }

    .progress-bar, .bar {
        background-color: color(primary-300);
        border-radius: $base-border-radius;
        height: 100%;
        transition: $base-width-transition;
    }
}

.progress-ring {
    transform: scale(-1, 1) rotate(-90deg);

    &.progress-normal .filler {
        stroke: color(success-300);
    }
    &.progress-warning .filler {
        stroke: color(alert-300);
    }
    &.progress-danger .filler {
        stroke: color(error-300);
    }

    &.progress-danger, &.progress-warning, &.progress-normal {
        .prog {
            stroke: color(base-300);
        }
    }

    &.primary {
        .prog {
            stroke: color(primary-300);
        }
    }

    &.primary, &.secondary {
        .filler {
            stroke-opacity: .3;
        }
    }

    .prog {
        transition: stroke-dashoffset .1s ease-in-out;
    }
}
