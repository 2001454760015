// Abstracts
@use "abstracts/index" as *;

.tabbed-views {

    ul.nav {

        li { cursor: pointer; }

        .nav-item.active {
            color: color(primary-300);
        }
    }

    & > footer {
        margin-top: box-model(m);
        text-align: right;
    }
}
