// Abstracts
@use "abstracts/index" as *;

// TODO: clear this whole mess up ASAP

body.login {
    background-color: #f7f7f7;

    h1 {
        margin-bottom: 70px
    }

    h3 {
        font-size: 18px;
        border: 0;
    }

    .btn-flat {
        display: inline-block;
        margin: 0;
        line-height: 15px;
        vertical-align: middle;
        font-size: 12px;
    }

    bs-login-form {
        display: block;
    }

    #login-form,
    .modal-diagnostics {
        padding-top: 80px;
        padding-top: 8vw;

        &, div:not(.tooltip-inner) {
            border-color: transparent;
            background: transparent;
            box-shadow: none;
        }

        .header-loading {
            text-align: center;
        }
    }

    #login-form {
        h1 img {
            transform: scale(1.06);
            cursor: pointer;
        }

        h3.header {
            margin-bottom: 30px;
        }

        form {
            margin: 0;
        }

        label {
            font-weight: bold;
        }

        input[type='text'],
        input[type='email'],
        input[type='password'] {
            font-size: 15.5px;
            background-image: none;
            background-size: initial;
            background-color: white;
            box-sizing: border-box;
            margin-top: 2px;
        }

        .btn-submit,
        input[type='text'],
        input[type='email'],
        input[type='password'] {
            width: 100%;
            height: 45px;
        }

        .btn-submit,
        .alert-login-failed {
            z-index: 1; // to overlay the lastpass hotspot
            padding: 1.1em;
        }

        .alert-login-failed {
            text-align: left;
            box-sizing: border-box;
        }

        .confirmation-mode-messages {
            width: 270px;
            margin: auto;

            .alert {
                padding: 8px 14px;
            }

            a {
                text-transform: uppercase;
                border-bottom: 1px dotted #a7a0c5;
            }
        }

        section {
            text-align: center;

            > p:first-child {
                line-height: 8em;
                .icon-lock {
                    font-size: 2em;
                    width: auto;
                    height: auto;
                    padding: 11px;
                    color: rgb(75, 75, 75);
                    border: 1px dashed #aaa;
                    border-radius: 1em;
                }
            }

            > p:last-child {
                line-height: 5em;
                input[type='submit'] {
                    float: none;
                }
            }
        }

        .login-credentials-form {
            width: 270px;
            margin: auto;

            .alert .close {
                right: 0;
            }
        }

        .login-field-box {
            position: relative;
            min-height: 48px;

            i[class^="icon-"] {
                position: absolute;
                top:  18px;
                left: 16px;
                z-index: 100;
                color: #333;
            }

            input {
                position: absolute;
                top:  0; left: 0;
                padding-left: 39px;
            }
        }

        .login-footer,
        .login-footer-links {
            text-transform: uppercase;
            a:hover {
                text-decoration: none;
            }
        }

        .login-footer {
            font-size: 12px !important;
            padding-top: 61px;
            padding-bottom: 3em;
        }

        .login-footer-links {
            font-size: 11px;
            margin: 0;
            text-align: center;
            list-style-type: none;
            li {
                display: inline-block;
                padding: 0 11px;
                &.reset-password-link-item a,
                &.return-to-login-link-item a {
                    border-bottom: 1px dotted #A7A0C5;
                    padding: 0 1px 2px;
                }
            }
        }

        .btn-submit {
            text-transform: uppercase;
            text-shadow: 0 1px 0 rgba(0,0,0,0.2);

            &,
            &:hover,
            &:active {
                background-color: #fb4f15;
                border-color: transparent;
                color: #fefefe;
                font-weight: bold;
                letter-spacing: 1px;
            }

            &:active {
                padding-top: 1.3em;
                color: #fcfcfc;
            }

            &.working {
                cursor: wait;
            }
        }

        .btn-flat {
            box-shadow: 0px 2px 2px 0px rgba(255, 255, 255, 0.3) inset;
        }

        .pass-strength,
        .error {
            text-align: left;
            font-size: 12px;
            margin-top: -10px;
            margin-bottom: 15px;

            .weaker {
                color: red;
            }

            .weak {
                color: orange;
            }

            .okay,
            .strong,
            .stronger {
                color: green;
            }
        }

        .error {
            color: red;
        }

        .form-group {
            text-align: left;
        }
    }
}
