// Abstracts
@use "abstracts/index" as *;

.org-profile-view {
    .nav-list {
        li.api {
            margin-top: box-model(xs);
            border-top: 1px solid color(base-500);
            padding-top: box-model(xs);
        }
    }
}

.webhooks-manager {
    .webhook {
        > div {
            margin-bottom: box-model(xs);
        }

        .btn-group {
            float: right;
        }

        .name {
            @include ellipsis;
        }
    }
}

.organisation-details {
    .loader.in-page {
        min-height: box-model(h);
    }
}
