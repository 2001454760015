@use "sass:math";

// Abstracts
@use "abstracts/index" as *;

.event-type-selection-step-view--intro-uberbox {
    text-align: center;
    padding: 20px 40px 0;
    background: color(primary-500);
    user-select: none;

    margin-top: - box-model(l);
    margin-left: $base-gutter * -1;
    margin-right: $base-gutter * -1;

    h3 {
        color: color(base-000);
        border: 0;
    }

    ul {
        @include clearfix;
        list-style-type: none;
        margin: auto;
        padding: 0;
    }

    li.tab {
        background-color: color(primary-300);
        border-radius: $base-border-radius $base-border-radius 0 0;
        color: color(base-000);
        cursor: pointer;
        display: inline-block;
        margin: 0 box-model(xs);
        padding: math.div(box-model(s), 2) box-model(s);

        &:hover {
            background-color: color(primary-305);
        }

        &.active {
            color: color(base-700);
            background-color: color(base-000);
        }
    }
}

.event-type-selection-step-view--proceed-form {
    width: 220px;
    text-align: left;
    margin: box-model(l, auto);
}

.event-type-selection-step-view--content {
    margin-top: box-model(l);
    margin-bottom: box-model(l);
    text-align: center;
}

.event-type-selection-step-view--uber-intro-icon {
    @include icon-size(l);
    border-radius: 100%;
    background: color(warning-100);
    color: color(warning-500);
    margin-bottom: box-model(l);

    &.production {
        background: color(success-100);
        color: color(success-500);
    }
}

.event-type-selection-step-view--proceed-form {
    margin: box-model(l, auto);
}

.event-type-selection-step-view--uber-intro-button {
    margin-left: 0 !important; // TODO: well... should I say what?
    width: 100%;
}
