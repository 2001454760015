// Abstracts
@use "abstracts/index" as *;

%links {
    border-radius: $base-border-radius;
    border: 1px solid color(base-100);
    padding: box-model(m);
    margin-bottom: box-model(l);
    box-shadow: box-model(0, xxxs, xxxs) colorAlpha(base-700, 0.04);

    i {
        @include font-size(document);

        vertical-align: middle;
        margin-right: box-model(xs);
        display: inline-block;
        margin-top: box-model(-xxxs);
    }

    > span {
        @include font-weight(semibold);

        color: color(base-600);
        float: left;
        padding-right: box-model(m);
    }
}

.quick-links-display {
    @extend %links;

    border: 1px solid color(primary-100);
    background-color: color(primary-050);

    i {
        @include font-size(xm);

        margin-right: box-model(xxs);
    }

    .actions {
        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
        }

        li {
            display: inline-block;
            margin-left: box-model(m);

            a {
                padding: box-model(xxs, xs);
            }
        }
    }
}

.event-dashboard,
.content-hub-dashboard {
    .pending-registrations {
        @extend %links;

        .pending, .no-pending {
            color: color(base-600);
            display: inline-block;
            margin-left: box-model(m);
        }

        span.count {
            @include font-size(xm);
            @include font-weight(semibold);

            color: color(base-700);
            display: inline-block;
            margin-top: box-model(-xxs);
            margin-right: box-model(xs);
            vertical-align: middle;
        }

        .no-pending {
            color: color(base-500);
        }

        .link {
            display: inline-block;
            padding-left: box-model(m);
            margin-left: box-model(m);
            border-left: 1px solid color(base-300);
        }
    }
}

