// Abstracts
@use "abstracts/index" as *;

.content-wrapper.maps:has(#map-editor-map) {
    max-width: none;
    padding-bottom: 0;
    padding-right: 0;

    .leaflet-container {
        background-color: color(base-100);
        border-top-left-radius: $base-border-radius;
    }
}
