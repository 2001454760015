@use "sass:math";

// Abstracts
@use "abstracts/index" as *;

%menu-pinned {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    width: $main-menu-width;
    transition: $main-menu-collapse-transition;
}

nav.main {
    @extend %menu-pinned;
    box-sizing: content-box;
    bottom: 0;
    overflow: hidden;
    z-index: z-index(menu);
    padding-right: $main-menu-toggler-size;

    &:hover {
        > .toggler::before {
            opacity: 1;
        }
    }

    &.animating {
        .sidemenu, .heading, .menus, .submenu {
            overflow: hidden;
        }
    }

    &.submenu-open:not(.minified) {
        .sidemenu, .heading .icon {
            background-color: color(base-300);
        }

        .heading .workspace {
            padding-left: box-model(m);

            &::after {
                display: block;
            }
        }

        .top-level-menu, .baseline {
            span.text {
                display: none;
            }
        }

        .baseline {
            width: $main-menu-user-menu-size;
        }

        .top-level-menu {
            width: $main-menu-minimized-width;

            > ul > li {
                &:hover {
                    a {
                        background-color: color(base-100);
                    }

                    .bullet {
                        border-color: color(base-100);
                    }
                }

                &.selected {
                    &.has-sub:not(.active) > a {
                        color: color(primary-300);
                        background-color: color(base-100);
                    }

                    &.active > a,
                    &.active:hover > a {
                        color: color(primary-300);
                        background-color: color(base-100);
                    }

                    &:hover {
                        > a {
                            background-color: color(primary-300);
                            color: color(base-000);
                        }
                    }
                }

                &.active {
                    padding-left: 0;
                    padding-right: 0;

                    a {
                        background-color: color(base-100);
                        color: color(base-700);
                        border-radius: 0;
                        padding-left: box-model(l) + 2;
                        padding-right: box-model(l) + 2;
                    }

                    .bullet {
                        border-color: color(base-100);
                    }
                }
            }

            .counter {
                display: none;
            }
        }

        .bullet {
            border-color: color(base-300);
        }

        *[delayed-tooltip]::after {
            display: block;
        }
    }

    > .toggler {
        @include iconify('ic-chevron-left');
        height: 100vh;
        position: absolute;
        top: 0;
        right: 0;
        width: $main-menu-toggler-size;
        cursor: pointer;
        color: color(primary-300);

        &::before {
            position: absolute;
            display: block;
            top: 50%;
            left: 50%;
            width: $main-menu-toggler-size;
            height: $main-menu-toggler-size;
            margin-top: math.div($main-menu-toggler-size, -2);
            margin-left: math.div($main-menu-toggler-size, -2);
            transition: $base-opacity-transition;
            text-align: center;
            opacity: 0;
        }
    }

    .sidemenu {
        background-color: color(base-100);
        height: 100vh;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        right: $main-menu-toggler-size;
        left: 0;
        bottom: 0;
        padding: 0;

        li {
            white-space: nowrap;
            overflow: hidden;
        }
    }

    $heading-padding-top: box-model(l);
    $heading-padding-bottom: box-model(s);
    $heading-height: $heading-padding-top + icon-size(m) + $heading-padding-bottom;
    $main-elements-side-paddings: box-model(m);

    .heading {
        @extend %menu-pinned;
        display: flex;
        z-index: 2;

        .icon, .logo, .workspace {
            flex: 1;
            padding: box-model(l, m, s);
            vertical-align: middle;
        }

        .icon {
            height: icon-size(m);
            width: icon-size(m);
            max-width: icon-size(m);
            min-width: icon-size(m);

            img {
                border-radius: $base-border-radius;
                max-height: 100%;
                width: 100%;
            }
        }

        .logo {
            align-self: center;

            img {
                vertical-align: middle;
            }
        }
    }

    .workspace {
        background-color: color(base-100);
        display: inline-block;
        padding-left: box-model(s);
        position: relative;
        box-sizing: border-box;
        max-width: $main-menu-width - icon-size(m) - $main-elements-side-paddings * 2;

        &::after {
            content: '';
            position: absolute;
            bottom: -1px;
            left: box-model(m);
            right: box-model(m);
            display: none;
            height: 1px;
            border-bottom: 1px solid color(base-500) ;
        }

        .name {
            @include font-size(base);
            @include font-weight(semibold);
            @include line-height(base);
            @include ellipsis;
            display: block;
            padding-right: box-model(l);
        }

        .stage {
            @include font-size(s);
            @include line-height(xxs);
            display: block;
        }

        .preview {
            position: absolute;
            top: box-model(xl);
            right: box-model(m);
            padding: box-model(xxs);
            line-height: 0;
            border: 1px solid color(base-500);
            background-color: color(base-000);
            color: color(base-600);
            border-radius: $base-border-radius;

            &:hover {
                cursor: pointer;
                background-color: color(base-100);
            }

            i {
                @include font-size(document);
            }
        }
    }

    $base-line-edges-paddings: box-model(l);
    $base-line-height: 80px + 16px /* icons */ + ($base-line-edges-paddings * 2);

    .baseline {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: $base-line-edges-paddings box-model(m);
    }

    .menus, .submenu {
        position: absolute;
        top: $heading-height;
        bottom: $base-line-height;
        left: 0;
        right: 0;
        overflow: auto;
        padding-top: box-model(xs);
        padding-bottom: box-model(xs);
    }

    .top-level-menu {
        i {
            @include font-size(xm);
            vertical-align: middle;
            display: inline-block;
            margin-top: -3px; // Icons specific

            + span.text {
                margin-left: box-model(m);
            }
        }

        > ul > li {
            padding-left: $main-elements-side-paddings;
            padding-right: $main-elements-side-paddings;
            margin-bottom: box-model(xs);

            &.selected,
            &.selected:hover {
                > a {
                    background-color: color(primary-300);
                    color: light-color(base-000);
                }
            }

            &:hover {
                a {
                    background-color: color(base-300);
                }

                .bullet {
                    border-color: color(base-300);
                }
            }

            a {
                padding: box-model(xs) box-model(xs) + 2;
                max-height: line-height-original(base);
            }
        }

        *[delayed-tooltip]::after {
            display: none;
        }
    }

    ul.externals {
        margin-top: box-model(xs);
        border-top: 1px solid color(base-300);
        padding-top: box-model(xs);

        i {
            color: color(primary-300);
        }
    }

    .submenu {
        background-color: color(base-100);
        left: $main-menu-minimized-width;
        bottom: 0;
        padding: box-model(0, m, l);
        display: none;

        &.in {
            display: block;
        }

        h3 {
            @include line-height(l);
            @include font-size(s);
            @include font-weight(semibold);
            color: color(base-600);
            text-transform: uppercase;
            letter-spacing: letter-spacings(l);
            margin: 0;
            padding-bottom: 0;
            padding-top: $separator-gutter;
            border-bottom: 0;
        }

        > ul {
            border-bottom: 1px solid color(base-500);
            margin-bottom: $separator-gutter - 1px; // border size
            padding-bottom: $separator-gutter;

            &:last-of-type {
                border: 0;
                margin-bottom: 0;
                padding-bottom: 0;
            }

            > li {
                &.selected > a,
                &.selected.hover > a,
                &.selected:hover > a {
                    background-color: color(primary-300);
                    color: light-color(base-000);
                }

                > a:hover {
                    background-color: color(base-300);
                }
            }
        }
    }

    $bulllet-border-thikness: 2px;

    .bullet {
        height: box-model(xs);
        width: box-model(xs);
        background-color: color(primary-300);
        border-radius: box-model(xs);
        border: $bulllet-border-thikness solid color(base-100);
        display: inline-block;
    }

    i {
        position: relative;

        .bullet {
            position: absolute;
            top: $bulllet-border-thikness * -1;
            right: $bulllet-border-thikness * -1;
        }
    }
}

.user-menu {
    line-height: 0;

    .help {
        @include font-size(xm);
        @include line-height(l);

        display: block;
        margin-bottom: box-model(m);

        .activator {
            background-color: color(base-500);
        }

        i {
            @include font-size(xm);
            @include line-height(l);
            color: color(base-000);
        }
    }

    .support {
        @include font-size(xm);
        @include line-height(xl);

        display: block;
        height: $main-menu-user-menu-size;
        width: $main-menu-user-menu-size;
        font-size: 20px;
        text-align: center;
        background-color: color(base-500);
        border-radius: 100%;
        color: color(base-000);
        margin-bottom: box-model(m);
    }

    .activator {
        background-color: color(alt-1-300);
        border-radius: 100%;
        color: color(base-000);
        cursor: pointer;
        height: $main-menu-user-menu-size;
        line-height: $main-menu-user-menu-size;
        text-align: center;
        width: $main-menu-user-menu-size;
    }

    .anchor-sw.dropdown-vue.menu {
        margin-left: 0;
        margin-top: box-model(xs) * -1;
        transform: translate3d(0, calc(-100% - #{box-model(xxl)}), 0);
    }
}

.theme-switch {
    float: right;
    display: block;
    margin-top: box-model(m);

    .slide {
        cursor: pointer;
        display: block;
        width: box-model(xxl);
        height: math.div(box-model(xxl), 2);
        position: relative;
        overflow: hidden;
        border-radius: box-model(m);
        background-color: color(base-500);
        margin: 0;
    }

    span.switch {
        display: block;
        height: box-model(m);
        width: box-model(m);
        border-radius: box-model(m);
        background-color: color(primary-300);
        position: absolute;
        padding: box-model(xxxs);
        top: 0;
        left: 0;
        transition: $togglers-transition;
        color: light-color(base-000);

        &.dark {
            left: calc(100% - #{math.div(box-model(xxl), 2)});
        }
    }

    i {
        display: block;
        margin: 1px 0 0 1px;
    }
}

.global-hotkeys {
    th, td {
        vertical-align: top;
    }

    th.shortcut {
        white-space: nowrap;
    }
}

%default-main-menu-minified {
    nav.main {
        overflow: visible;

        &, .heading {
            width: $main-menu-minimized-width;
        }

        > .toggler {
            &::before {
                transform: rotate(180deg);
            }
        }

        .heading .workspace, .submenu {
            &, &.in {
                display: none;
            }
        }

        .menus {
            overflow-x: hidden;
            overflow-y: auto;
        }

        span.text, span.counter {
            display: none;
        }
    }
}
