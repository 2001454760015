// Abstracts
@use "abstracts/index" as *;

.file-dropper {
    .allowed-factors {
        margin-bottom: box-model(s);
        color: color(base-500);
    }

    .drop-zone {
        min-height: box-model(m) * 10;
        border-radius: $base-border-radius;
        border: 2px dashed color(base-300);
        text-align: center;
        padding: box-model(xl);
        box-sizing: border-box;
        position: relative;

        &.active {
            border-color: color(primary-500);
            background-color: color(primary-050);
        }

        i {
            @include icon-size(l);
            color: color(primary-300);
            margin-bottom: box-model(s);
        }

        input {
            position: absolute;
            height: 1px;
            width: 1px;
            top: 0;
            left: 0;
            opacity: 0;
            z-index: 0;
        }
    }
}
