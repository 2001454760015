// Abstracts
@use "abstracts/index" as *;

.sessions-registration-configuration {

    &.registration .title-and-actions {
        padding-left: box-model(s);
        border-radius: $large-border-radius;

        &:has(+ .group-config) {
            border-radius: $large-border-radius $large-border-radius 0 0;

            .input-wrapper {
                border-radius: $large-border-radius $large-border-radius 0 0;
            }
        }

        .input-wrapper {
            border-radius: $large-border-radius;
            padding-left: box-model(s);
        }
    }

    .heading {
        margin-bottom: box-model(m);

        .btn {
            float: right;
        }
    }

    .empty-state {
        text-align: center;
        padding: box-model(l);
        border: 1px solid color(base-300);
        border-radius: $large-border-radius;
    }

    .group {
        &.collapsed {
            .title-and-actions {
                border-bottom-color: color(base-300);
            }

            .title-and-actions, .input-wrapper {
                border-radius: $large-border-radius;
            }

            .toggler {
                transform: rotate(180deg);
            }

            .group-config {
                overflow: hidden;
                padding-top: 0;
                padding-bottom: 0;
                opacity: 0;
                border-top-width: 0;
                border-bottom-width: 0;
                height: 0;
            }
        }
    }

    .group-config {

        border: 1px solid color(base-300);
        border-top: 0;
        border-radius: 0 0 $large-border-radius $large-border-radius;
        box-sizing: border-box;
        padding: box-model(s);
        margin-bottom: box-model(l);

        transition:
            opacity .2s linear,
            padding .2s linear,
            border-top-color 2s linear;
    }

    .session-import-export {
        display: flex;
        gap: box-model(xl);
        .session-import {
            display: inline-block;
        }
        .session-export {
            display: inline-block;
            align-self: flex-start;
            min-width: box-model(xs) * 29;
            padding: box-model(m, m);
            border-radius: box-model(xs);
            border: 1px solid color(base-300);
        }
        .subtitle {
            @include font-size(base);
            @include font-weight(normal);
            @include line-height(s);

            color: color(base-600);
            margin-top: box-model(xxs);
            margin-bottom: box-model(m);
        }
    }
}
