// Abstracts
@use "abstracts/index" as *;

$color-ok: #468847;
$color-warn: #c09853;
$color-danger: #b94a48;

@mixin pagination {
    display: flex;
    margin: 6px;
    border: 1px solid color(base-300);
    border-radius: 5px;
    float: right;
    height: auto;

    .next,
    .prev {
        width: 60px;
        text-align: center;
        padding: 5px;
        color: #0088cc;
    }

    .next:hover,
    .prev:hover {
        background-color: color(base-100);
        cursor: pointer;
    }

    .next.disabled,
    .prev.disabled {
        background-color: color(base-100);
        color: #a9a4a4;
    }

    .prev {
        border-right: 1px solid color(base-300);
    }
}

bs-org-entitlements-manager {
    display: block;
    position: relative;

    .heading {
        .actions {
            float: right;
            margin: 0;
        }
    }

    bs-org-entitlements {
        display: block;

        .representative {
            float: right;
            margin: box-model(0, 0, m, m);
            padding: box-model(m);
            border: solid 1px color(base-300);
            border-radius: $base-border-radius;
        }

        .loader-container {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 260px;
        }

        i.icon-loader {
            display: inline-block;
            animation: spin 2s linear infinite;
            font-size: 40px;
        }

        .superadmin-controls {
            margin-top: 20px;
        }

        legend {
            margin-bottom: 10px;
        }
    }

    bs-org-entitlements-exporter {
        text-align: right;
        margin-bottom: 15px;
    }

    .btn + bs-org-entitlements-exporter {
        margin-left: box-model(xs);
    }

    bs-org-entitlement-transactions {
        display: block;

        .loader-container {
            display: block;
            height: auto;
            position: absolute;
            top: 39px;
        }

        i.icon-loader {
            display: inline-block;
            animation: spin 2s linear infinite;
            font-size: inherit;
        }

        .intro-text {
            margin-bottom: 25px;
        }

        bs-org-entitlements-table {
            .loader-container {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 260px;
                position: relative;
            }

            i.icon-loader {
                display: inline-block;
                animation: spin 2s linear infinite;
                font-size: 40px;
            }
        }
    }

    bs-org-entitlements-table {
        display: block;

        .table-body-row + .table-body-row {
            border-top: none;
        }

        tr.collapsible {
            td {
                padding: 0;
                transition: border-top 0.01s ease-in-out;
            }
            .details-container {
                max-height: 0;
                overflow: hidden;
                transition: all 0.5s ease-in-out;
            }
        }

        tr.collapsible.expanded {
            td {
                border-top: 0;
            }
            .details-container {
                max-height: 1000px;
            }
        }

        .table.table-striped {
            margin-bottom: 0;

            [class*="span"] {
                margin-left: 0;
            }

            .tr {
                float: left;
                margin-left: 0;
            }
        }
    }

    bs-org-event-entitlements {
        display: block;
        margin-top: 25px;

        .event-entitlements-tabs .nav.nav-tabs{
            width: 80%;
            float: left;
        }

        bs-org-entitlements-exporter {
            width: 20%;
            float: right;
            text-align: right;
        }

        .clearfix {
            clear: both;
        }

        .panel {
            padding: 15px;
            padding-bottom: 0px;
            margin-bottom: 20px;
            background-color: color(base-000);
            border: 1px solid color(base-300);
            border-radius: 4px;
            -webkit-box-shadow: 0 1px 1px colorAlpha(base-700, 0.05);
            box-shadow: 0 1px 1px colorAlpha(base-700, 0.05);
        }

        .panel-heading {
            padding: 10px 15px;
            margin: -15px -15px 0px;
            font-size: 17.5px;
            font-weight: 350;
            background-color: color(base-100);
            border-bottom: 1px solid color(base-300);
            border-top-right-radius: 3px;
            border-top-left-radius: 3px;
        }

        .panel-body {
            padding: 10px 15px;
            margin: 0px -15px 0px;
            border-bottom: 1px solid color(base-300);
            position: relative;
            span {
                position: relative;
                z-index: 2;
            }
        }

        .panel-body:last-of-type {
            border-bottom: none;
            .usage-gauge {
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }

        .panel-footer {
            padding: 10px 15px;
            margin: 15px -15px -15px;
            background-color: color(base-100);
            border-top: 1px solid color(base-300);
            border-bottom-right-radius: 3px;
            border-bottom-left-radius: 3px;
        }

        .clearfix > .loader-container {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 260px;
            position: relative;

            i.icon-loader {
                display: inline-block;
                animation: spin 2s linear infinite;
                font-size: 40px;
            }
        }

        .event-creation-date {
            padding-left: 10px;
            font-size: 12px;
            font-weight: normal;
        }
        .assign-entitlements-button {
            cursor: pointer;
        }

        .history {
            font-size: small;
        }

        .add-entitlement:hover {
            text-decoration: none;
        }
    }

    .pagination {
        @include pagination;
    }

    .footnote {
        margin-top: 20px;
        font-size: 10px;
        color: #c1c1c1;
    }

    bs-org-assign-entitlement {

        table {
            margin-top: 20px;
        }
        .table-body-row + .table-body-row {
            border-top: none;
        }

        tr.collapsible {
            td {
                padding: 0;
                transition: border-top 0.01s ease-in-out;
            }
            .details-container {
                max-height: 0;
                overflow: hidden;
                transition: all 0.5s ease-in-out;
            }
        }

        tr.collapsible.expanded {
            td {
                border-top: 0;
            }
            .details-container {
                max-height: 1000px;
            }
        }

        .table.table-striped {
            [class*="span"] {
                margin-left: 0;
            }

            .tr {
                float: left;
                margin-left: 0;
            }
        }

        #assign-entitlement-to-event {
            table {
                margin-top: 20px;
            }
            form.form-horizontal.quantity {
                margin: 25px 0;
            }
            label[for="quantity"] {
                text-align: left;
            }
            input#quantity {
                width: 35%;
            }
            .row-fluid.summary {
                margin: 40px 0;
            }
        }

        label[for=quantity] {
            text-align: left !important;
        }

        .form-horizontal .controls {
            margin-left: 0;
        }

        .input-container{
            margin-bottom: 15px;;
        }

        .loader-container {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 260px;
            position: relative;
        }

        i.icon-loader {
            display: inline-block;
            animation: spin 2s linear infinite;
            font-size: 40px;
        }

        .summary {
            margin-top: 20px;
            margin-bottom: 20px;
        }

        .summary-organization,
        .summary-event {
            padding: 10px 20px;
            box-sizing: border-box;
            color: #666666;
            text-align: center;
            h3 {
                margin-bottom: 10px;
            }
        }

        .summary-direction {
            text-align: center;
            font-size: 50px;
            margin-top: 25px;
        }
    }

    bs-org-entitlement-details {
        margin: 0 10px 10px 10px;
        padding: 10px;
        border: solid 1px #ddd;
        display: block;
        background: white;
        color: #666;
        ul {
            margin-bottom: 0;
        }
        h5 {
            margin-bottom: 10px;
            &.extra {
                margin-top: 10px;
            }
        }
    }

    bs-org-per-event-entitlements {
        .loader-container {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 260px;
            position: relative;
        }

        i.icon-loader {
            display: inline-block;
            animation: spin 2s linear infinite;
            font-size: 40px;
        }

        .page-info {
            margin-bottom: 18px;
        }
    }

    bs-org-entitlement-product-block {
        .usage-gauge {
            z-index: 1;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.2;
        }

        .usage-ok,
        .usage-used {
            color: $color-ok;
            .usage-gauge {
                background-color: $color-ok;
            }
        }

        .usage-warn,
        .usage-error {
            color: $color-warn;
            .usage-gauge {
                background-color: $color-warn;
            }
        }
        .usage-ko {
            color: $color-danger;
            .usage-gauge {
                background-color: $color-danger;
            }
        }

        .availability span {
            position: relative;
        }

        .availability .usage-info {
            position: absolute;
            width: 100%;
            height: 100%;
        }

        .usage-can-be-added {
            .product-name {
                cursor: pointer;
            }
        }

        .add-entitlement {
            font-size: 17.5px;
            margin-left: 10px;
            vertical-align: middle;
            color: #0051A3;
        }

        .settle-over-usage {
            margin-left: box-model(m);
            font-weight: 350;
        }
    }

    bs-org-entitlements-exporter {
        display: inline-block;
    }

    .product-accordion {
        display: block;
        border: 1px solid #e5e5e5;

        &:not(:last-child) {
            border-bottom: none;
        }

        .accordion-heading {
            padding: 8px 15px;
            background-color: #f3f3f3;
            font-weight: bold;
        }

        .accordion-body {
            max-height: 0;
            overflow: hidden;
            text-align: center;
            transition: all 0.5s ease-in-out;
        }

        &.expanded {
            .accordion-body {
                max-height: 10000px;
            }
        }

        .transactions-loading-indicator {
            i.icon-loader {
                margin-left: 10px;
                font-size: inherit;
            }
        }
    }

    .product-icon {
        color: #bbb;
        margin-right: 10px;
    }

    .add-product {
        cursor: pointer;
        margin-left: 5px;
        color: #4c4c4c;
    }

    .product-info {
        display: flex;
        align-items: center;

        .indicator-icon {
            margin-left: 10px;
            color: #4c4c4c;
        }
    }

    .text-success {
        color: $color-ok;
    }

    .text-warning {
        color: $color-warn;
    }

    .text-error {
        color: $color-danger;
    }
}

bs-org-entitlement-overage-report {
    .indicator-icon {
        color: #29323d !important;
        margin-top: 5px;
        display: block;
    }
    .expanded {
        margin-bottom: 10px;
    }
    a, a:hover, a:active {
        color: $color-danger;
    }
    .loader-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
    }

    i.icon-loader {
        display: inline-block;
        animation: spin 2s linear infinite;
        font-size: 20px;
    }
}

.entitlements-export-modal {

    .download-completed {
        text-align: center;
        margin: 25px 0;
    }

    i.icon-check {
        display: block;
        margin: 0 auto 10px auto;
        background-color: #2bb12f;
        color: color(base-000);
        width: 45px;
        height: 45px;
        line-height: 45px;
        font-size: 30px;
        border-radius: 22px;
    }
}

bs-org-product-mappings {
    .btn.btn-primary {
        margin: 6px;
    }

    .pagination {
        @include pagination;
    }
}
