// Abstracts
@use "abstracts/index" as *;

%workspaces {
    position: relative;

    .loader {
        padding: 0;

        .loading {
            left: 50%;
        }
    }

    .search {
        background-color: color(base-000);
        top: 0;
        z-index: 3;
        display: flex;
        flex-wrap: wrap;

        h1 {
            flex: 1 0 100%;
        }

        .create {
            flex-grow: 1;
            align-items: flex-start;
            margin-bottom: box-model(m);
            align-self: flex-start;
        }

        .search-bar {
            margin-bottom: box-model(m);
            display: inline-block;
            position: relative;
            flex-grow: 0;
        }

        .btn-group {
            button {
                &:active, &:focus {
                    box-shadow: none;
                }
            }
        }
    }

    .browse {
        margin-left: box-model(m);
    }

    .section-list {
        overflow: auto;

        .last, .live, .future, .past {
            margin-bottom: box-model(l);
        }
    }

    .workspaces-wrapper {
        margin: box-model(m, 0);
    }

    .listing-grid {
        grid-template-columns: repeat(auto-fill, minmax($list-tile-width-dashboard, 1fr));
    }

    .archived {
        opacity: 0.5;
    }

    tbody tr {
        .actions i{
            @include font-size(document);
            visibility: hidden;
            cursor: pointer;
            vertical-align: middle;
        }
        &:hover .actions i {
            visibility: visible;
        }
    }

    .no-data {
        text-align: center;
        display: block;
        padding-top: 10%;

        img {
            height: auto;
            max-width: 100%;
        }

        h1 {
            @include font-weight(semibold);
            @include font-size(xxl);
            margin: box-model(m);
            line-height: line-height(r);
        }

        button {
            margin-top: box-model(m);
            margin-bottom: box-model(m);
        }

        a {
            display: inline-block;
            margin: box-model(xs);
        }

        .create {
            float: none;
        }
    }
}

.page.events-list,
.page.webinars-list,
.page.templates-list,
.page.webinar-templates {
    @extend %workspaces;
}
