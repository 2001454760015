// Abstracts
@use "abstracts/index" as *;

.assets-uploader.invisible-until-custom-styles-loaded {
    visibility: visible;
}

.assets-uploader {
    .center {
        text-align: center;
    }

    .column {
        display: inline-block;
        width: 200px;
        margin-right: 50px;

        small {
            display: block;
            margin-top: 10px;
            font-size: 0.8em;
        }

        &.first small {
            margin-right: 35px;
        }

        &.tablet {
            width: 380px;
            margin: 0;
        }

        &.last {
            margin: 0;
        }
    }

    .first, .phone, .tablet {
        .bs-file-dropzone.thumbnail::before {
            display: none;
        }
    }

    .previews {
        padding: 30px 0;
    }

    .bs-file-dropzone {
        display: inline-block;
    }

    .app-icon-preview {
        .progress-container {
            height: 128px;
            line-height: 128px;
            padding-top: 55px;
        }

        .bs-file-dropzone-image {
            width: 128px;
            height: 128px;
            padding-top: 0;
            margin-left: 19px;
        }

        .bs-file-dropzone.thumbnail {
            background-image: none !important;
            width: 128px;
            height: 128px;
            border: none;
            line-height: 128px;
            padding: 0;
            border-radius: 0;
        }

        .thumbnail:after {
            height: 12px;
            right: 0;
            line-height: 12px;
            font-size: 8px;
            left: 14px;
            padding-left: 2px;
        }

        .app-icon-inner {
            background-color: #f3f3f3;
        }

        img {
            width: 128px;
            height: 128px;
        }
    }

    .column.phone, .column.tablet {
        .bs-file-dropzone-image {
            padding: 0;
            background: none;
            height: 412px;
        }

        .progress {
            background-color: none;
            border: none;
            box-shadow: none;
        }

        .bs-file-dropzone.thumbnail {
            padding: 0;
            border: none;
            background: none;
            background-image: none !important;
            height: initial;
            width: initial;
        }

        .progress-container {
            padding-top: 228px;
            height: 402px;
            line-height: 402px;
        }
    }

    .splash-screen {
        background: #f3f3f3;
        border-radius: 10px;
        box-shadow: rgba(0,0,0,0.1) 0 -1px 2px inset, #fff 0 2px 3px inset;
        border: 1px solid #ddd;
        padding: 30px 5px;
        line-height: initial;
        position: relative;

        .preview-wrapper {
            background-color: #000;
            border-radius: 4px;
        }

        img {
            display: block;
            width: 350px;
            height: 350px;
            border-radius: 4px;
        }

        .loading-screen {
            position: absolute;
            text-align: center;
            margin: 50px 0 0 10px;
            cursor: default;
            user-select: none;
            border-radius: 10px;
            padding: 10px 0;

            // backstage replaces .icon-spinner with .new-spinner and installs canvas based
            // spinner
            .icon-custom-spinner {
                width: 24px;
                height: 24px;
                font-size: 24px;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;

                &:before {
                    content: '\e907';
                    line-height: 1em;
                }
            }

            .progress-bar {
                width: 80%;
                height: 3px;
                margin: 10px auto;

                .bar {
                    width: 40%;
                    height: inherit;
                }
            }

            span {
                font-size: 0.9em;
            }
        }



        &.phone {
            img {
                width: 188px;
            }

            &::before {
                content: '';
                width: 40px;
                height: 3px;
                background: #c0c0c0;
                display: block;
                margin-left: -20px;
                position: absolute;
                top: 14px;
                left: 50%;
                box-shadow: rgba(0,0,0,0.5) 0 1px 1px inset;
            }

            &::after {
                content: '';
                width: 15px;
                height: 15px;
                background: #fff;
                display: block;
                position: absolute;
                box-shadow: rgba(0,0,0,.5) 0 0px 2px inset;
                border-radius: 50%;
                bottom: 8px;
                left: 50%;
                margin-left: -7.5px;
            }

            .loading-screen {
                width: 160px;
                margin-top: 140px;
            }
        }

        &.tablet {
            &::before {
                content: '';
                width: 5px;
                height: 5px;
                background: #000;
                display: block;
                margin-left: -2.5px;
                border-radius: 50%;
                position: absolute;
                top: 14px;
                left: 50%;
            }

            &::after {
                content: '';
                width: 15px;
                height: 15px;
                background: #fff;
                display: block;
                position: absolute;
                box-shadow: rgba(0,0,0,.5) 0 0px 2px inset;
                border-radius: 50%;
                bottom: 8px;
                left: 50%;
                margin-left: -7.5px;
            }

            .loading-screen {
                width: 335px;
                margin-top: 160px;
            }
        }
    }

    .toolbar {
        background-color: #f3f3f3;
        border: 1px solid #ddd;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 10px;
        text-align: right;

        h4 {
            padding: 0;
            margin: 0;
            line-height: 30px;
        }
    }

    .colour-board {
        display: flex;
        margin-bottom: 2em;

        input[type=range] {
            box-shadow: none;
        }

        input[type=range] + input[type=text] {
            margin-left: 5px;
            // When < 50px not all text is visible
            width: 50px !important;
        }

        .form-horizontal {
            float: none !important;
            width: auto !important;
            flex: 1;
            padding-left: box-model(m);
            padding-right: box-model(m);
        }
    }
}
