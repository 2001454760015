@use "sass:math";

// Abstracts
@use "abstracts/index" as *;
@use "default/components/main-menu" as *;
@use "default/components/common-toolbar" as *;

.minified-menu {
    @extend %default-main-menu-minified;

    .bs-common-toolbar, .secondary-page-toolbar {
        @extend %default-common-toolbar-menu-minified;
    }

    .loader {
        .loading {
            left: calc(50% + #{math.div($main-menu-minimized-width, 2)});
        }
    }
}

.modal-open {
    overflow: hidden;
}

.maximized {
    #intercom-container {
        display: none;
    }
}
