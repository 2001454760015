// Abstracts
@use "abstracts/index" as *;

.legal-documents-form {
    hr {
        margin-bottom: box-model(m);
    }

    fieldset > .form-groups {
        margin-bottom: box-model(m);
    }
}
