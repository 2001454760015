// Abstracts
@use "abstracts/index" as *;

// TODO: adapt values to variable files and move all possible selector to upper levels

.bt-metadata-ruleset {
    border-left: 1px solid color(base-100);
    opacity: 1;
    padding-right: 20px;

    &::before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        background: color(base-000);
        position: absolute;
        margin-left: -5px;
    }

    &:not(.last)::after {
        content: '';
        display: block;
        width: 10px;
        height: 15px;
        background: color(base-000);
        position: absolute;
        margin-left: -5px;
        margin-top: -59px;
    }
}

.bt-metadata-rule {
    padding: 0 0 0 5px;
    position: relative;

    &:hover .close {
        opacity: 1;
    }

    .close {
        position: absolute;
        right: -20px;
        opacity: 0;
        color: color(base-300);
    }

    .control-label::before {
        content: '';
        display: block;
        background: color(base-100);
        width: 12px;
        height: 1px;
        margin-left: -5px;
        position: relative;
        top: 10px;
    }

    .control-label {
        padding-top: 0;
    }

    .bt-metadata-rule-new-row {
        padding: 0;
        margin-top: 10px;
        margin-left: 5px;
        border: none;
    }
}

.bt-metadata-rule-field {
    font-weight: bold;
}

.bt-metadata-rule-comparator {
    margin-top: 5px;
}

.bt-metadata-rule-divider {
    font-weight: 500;
    padding: 10px 10px 0 10px;
    clear: both;

    &.or {
        background: color(base-000);
        margin-left: -10px;
        padding-top: 20px;
        padding-bottom: 10px;

        &::after {
            display: none;
        }

        span {
            padding-left: 0;
        }
    }

    span {
        background-color: color(base-000);
        padding: 0 5px;
    }
}

.bt-metadata-rule-inserter {
    clear: both;
    opacity: 0;
    margin-left: -12px;
    cursor: pointer;
    position: absolute;
    width: 100%;

    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: color(base-100);
        margin: -11px 0 0 0;
    }

    &:hover {
        opacity: 1;
    }

    .icon-plus-sign {
        background: color(base-000);
    }
}

.bt-metadata-rule-new-row {
    padding: 20px 0 0 10px;
    border-left: 1px solid color(base-100);

    &::after {
        content: '';
        display: block;
        width: 5px;
        height: 1px;
        background: color(base-100);
        margin: -12px 0 0 -10px;
        padding: 0 10px 0 0;
    }
}

/* adjusts form styles */
.bt-metadata-rule-form {
    .table {
        &, &.table-plain {
            margin: 10px 0 0 0;
        }

        tfoot {
            display: none;
        }

        td {
            padding: 0;
        }
    }

    .quill-editor,
    .select2-container,
    .uneditable-input,
    .form-generator-field-value>button,
    input[type=date],
    input[type=email],
    input[type=number],
    input[type=text],
    select,
    textarea {
        max-width: none;
        width: 100%;
    }
}

.bt-draggable {
    cursor: move;
}

.bt-dragged {
    position: absolute;
    z-index: 300;
    cursor: none;
}

.list-group {
    padding-left: 0;
    margin-bottom: 0;
}

.list-group-item {
    position: relative;
    display: block;
    padding: .75rem 1.25rem;
    margin-bottom: -1px;
    background-color: color(base-000);
    border: 1px solid color(base-100);

    &:first-child {
        border-top-left-radius: $base-border-radius;
        border-top-right-radius: $base-border-radius;
    }

    &:last-child {
        border-bottom-left-radius: $base-border-radius;
        border-bottom-right-radius: $base-border-radius;
    }
}
