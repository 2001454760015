// Abstracts
@use "abstracts/index" as *;

.buttons-toggler {
    display: inline-block;
    background-color: color(base-300);
    border: box-model(xxxs) color(base-300) solid;
    border-radius: $base-border-radius;

    .btn {
        @include line-height(base);

        padding-top: box-model(xxxs);
        padding-bottom: box-model(xxxs);
        margin: 0;

        &:focus {
            box-shadow: none;
        }
    }
}
