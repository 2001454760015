// Abstracts
@use "abstracts/index" as *;

@mixin shorthand($size: $baseFontSize, $weight: normal, $lineHeight: $baseLineHeight) {
    font-size: $size;
    font-weight: $weight;
    line-height: $lineHeight;
}

@mixin serif() {
    font-family: Georgia, 'Times New Roman', Times, serif;
}

@mixin sans-serif($size: $baseFontSize, $weight: normal, $lineHeight: $baseLineHeight) {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

    @if ($size) {
        @include shorthand($size, $weight, $lineHeight);
    }
}

@mixin monospace() {
    font-family: Menlo, Monaco, 'Courier New', monospace;
}

// Setup the mixins to be used
@mixin columns($gridGutterWidth, $gridColumnWidth, $gridRowWidth, $columns) {
    width: ($gridColumnWidth * $columns) + ($gridGutterWidth * ($columns - 1));
}

@mixin offset($gridColumnWidth, $gridGutterWidth, $columns) {
    margin-left: ($gridColumnWidth * $columns) + ($gridGutterWidth * ($columns - 1)) + ($gridGutterWidth * 2);
}

@mixin gridColumn($gridGutterWidth) {
    float: left;
    margin-left: $gridGutterWidth;
}

// Take these values and mixins, and make 'em do their thang
@mixin generate($gridColumns, $gridColumnWidth, $gridGutterWidth) {
    // Row surrounds the columns
    .row {
        margin-left: $gridGutterWidth * -1;
        @include clearfix();
    }

    // Find all .span# classes within .row and give them the necessary properties for grid columns (supported by all browsers back to IE7, thanks $dhg)
    [class*='span'] {
        @include gridColumn($gridGutterWidth);
    }

    // Default columns
    @for $i from 1 through 12 {
        .span#{$i} {
            @include columns($gridGutterWidth, $gridColumnWidth, $gridRowWidth, $i);
        }
    }

    // Offset column options
    @for $i from 1 through 12 {
        .offset#{$i} {
            @include offset($gridColumnWidth, $gridGutterWidth, $i);
        }
    }

    .span12 {
        margin-left: 0;
    }
}

@mixin inputColumns( $gridGutterWidth, $gridColumnWidth, $gridRowWidth, $columns) {
    width: (($gridColumnWidth) * $columns) + ($gridGutterWidth * ($columns - 1)) - 10;
}

@mixin generateInputGrid($gridColumns, $gridColumnWidth, $gridGutterWidth) {
    input,
    textarea,
    .uneditable-input {

        @for $i from 1 through 12 {
            &.span#{$i} {
                @include inputColumns($gridGutterWidth, $gridColumnWidth, $gridRowWidth, $i);
            }
        }
    }
}

// Webkit-style focus
// ------------------
@mixin tab-focus() {
    // Default
    outline: thin dotted #333;
    // Webkit
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

// Form field states (used in forms.less)
// --------------------------------------------------

// Mixin for form field states
@mixin formFieldState($textColor: #555, $borderColor: #ccc, $backgroundColor: #f5f5f5) {
    // Set the text color
    > label,
    .help-block,
    .help-inline {
        color: $textColor;
    }

    // Style inputs accordingly
    input,
    select,
    textarea {
        color: $textColor;
        border-color: $borderColor;

        &:focus {
            border-color: darken($borderColor, 10%);
            box-shadow: 0 0 6px lighten($borderColor, 20%);
        }
    }

    // Give a small background color for input-prepend/-append
    .input-prepend .add-on,
    .input-append .add-on {
        color: $textColor;
        background-color: $backgroundColor;
        border-color: $textColor;
    }
}

// Placeholder text
// -------------------------
@mixin placeholder($color: $placeholderText) {
    :-moz-placeholder {
        color: $color;
    }

    ::-webkit-input-placeholder {
        color: $color;
    }
}

// Setup the mixins to be used
@mixin fluidColumns($fluidGridGutterWidth, $fluidGridColumnWidth, $columns) {
    width: ($fluidGridColumnWidth * $columns) +
        ($fluidGridGutterWidth * ($columns - 1));
}

@mixin fluidGridColumn($fluidGridGutterWidth) {
    float: left;
    margin-left: $fluidGridGutterWidth;
}

// Take these values and mixins, and make 'em do their thang
@mixin generateFluidGrid($gridColumns, $fluidGridColumnWidth, $fluidGridGutterWidth) {
    // Row surrounds the columns
    .row-fluid {
        @include clearfix();
        width: 100%;

        // Find all .span# classes within .row and give them the necessary properties for grid columns (supported by all browsers back to IE7, thanks $dhg)
        > [class*='span'] {
            @include fluidGridColumn($fluidGridGutterWidth);
        }

        > [class*='span']:first-child {
            margin-left: 0;
        }

        @for $i from 1 through 12 {
            > .span#{$i} {
                @include fluidColumns($fluidGridGutterWidth, $fluidGridColumnWidth, $i);
            }
        }

    }
}

// Mixin for generating button backgrounds
// ---------------------------------------
@mixin buttonBackground($startColor, $endColor) {
    // gradientBar will set the background to a pleasing blend of these, to support IE<=9
    @include gradientBar($startColor, $endColor);

    // in these cases the gradient won't cover the background, so we override
    &:hover,
    &:active,
    &.active,
    &.disabled,
    &[disabled] {
        background-color: $endColor;
    }

    // IE 7 + 8 can't handle box-shadow to show active, so we darken a bit ourselves
    &:active,
    &.active {
        background-color: darken($endColor, 10%) e('\9');
    }
}

// Gradient Bar Colors for buttons and alerts
@mixin gradientBar($primaryColor, $secondaryColor) {
    background-image: linear-gradient(to top, $primaryColor, $secondaryColor);
    border-color: $secondaryColor $secondaryColor darken($secondaryColor, 15%);
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) fadein(rgba(0, 0, 0, 0.1), 15%);
}

@mixin arrowTop($arrowWidth: 5px) {
    bottom: 0;
    left: 50%;
    margin-left: -$arrowWidth;
    border-left: $arrowWidth solid transparent;
    border-right: $arrowWidth solid transparent;
    border-top: $arrowWidth solid $black;
}

@mixin arrowLeft($arrowWidth: 5px) {
    top: 50%;
    right: 0;
    margin-top: -$arrowWidth;
    border-top: $arrowWidth solid transparent;
    border-bottom: $arrowWidth solid transparent;
    border-left: $arrowWidth solid $black;
}

@mixin arrowBottom($arrowWidth: 5px) {
    top: 0;
    left: 50%;
    margin-left: -$arrowWidth;
    border-left: $arrowWidth solid transparent;
    border-right: $arrowWidth solid transparent;
    border-bottom: $arrowWidth solid $black;
}

@mixin arrowRight($arrowWidth: 5px) {
    top: 50%;
    left: 0;
    margin-top: -$arrowWidth;
    border-top: $arrowWidth solid transparent;
    border-bottom: $arrowWidth solid transparent;
    border-right: $arrowWidth solid $black;
}

@mixin stripedGradient($color, $angle: -45deg) {
    /* autoprefixer: off */
    background-color: $color;
    background-image: linear-gradient(
        $angle,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
    );
}
