// Abstracts
@use "abstracts/index" as *;

.forms-results {
    .forms-global-actions {
        padding-left: box-model(m) !important;
        padding-right: box-model(m) !important;
    }

    .card {
        margin: 0;

        ul.list-group li.list-group-item {
            // box-model(xl) compensates for inner .row negative margin-left
            // box-model(m) is the actual padding left
            padding-left: box-model(xl) + box-model(m);
        }
    }

    .card-footer, .card-header {
        padding: box-model(xs, m);
    }
}

.forms-options .choice-options {
    max-height: none !important;

    .choice-option-score {
        padding-left: box-model(xs);
        padding-right: box-model(xs);
        margin-left: box-model(xs);
    }
}

.fb-settings .card-header input.query-name-input {
    background-color: transparent !important;
}

@media print {
    .fb-main {
        width: auto !important;
    }

    .card.fb-settings,
    .fb-select-renderer {
        display: none !important;
    }

    .fb-result .question-title {
        width: auto !important;
        margin: 0 !important;
    }
}
