@use "sass:math";

// Abstracts
@use "abstracts/index" as *;

%upload-selector {
    @include font-size(base);
    @include font-weight(normal);
    @include line-height(s);

    border: 1px dashed color(base-500);
    border-radius: $base-border-radius;
    cursor: pointer;
    margin: 0;
    position: relative;
    overflow: hidden;
    text-align: center;
    transition: $base-border-transition;

    &:hover {
        border-color: color(base-700);
    }

    &:active, &.active {
        border-color: color(primary-300);
    }

    &.disabled, [disabled] {
        cursor: not-allowed;
        border-color: transparent;
        color: color(base-500);
    }

    input[type='file'] {
        position: absolute;
    }
}

.bs-file-dropzone {
    @extend %upload-selector;

    &.thumbnail {
        background-size: cover;
        background-position: center;

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            padding-top: $ratio-43;
        }
    }

    .drop-label {
        padding: math.div(box-model(s), 2) box-model(s);
    }

    .drop-area, input[type='file'] {
        pointer-events: none;
    }

    input[type='file'] {
        transform: translate(-200%);
    }
}

.btn-file {
    @extend %upload-selector;

    background: transparent;

    &:hover {
        background: transparent;
    }

    input[type='file'] {
        cursor: pointer;
        top: 0;
        left: 0;
        opacity: 0;
    }
}

.file-uploader {
    @extend %upload-selector;
    padding: box-model(xxs);

    .file-uploads {
        vertical-align: middle;

        &.file-uploads-html4 input,
        &.file-uploads-html5 label {
            margin: 0;
        }

        &.file-uploads-disabled {
            background-color: color(base-100);
            cursor: not-allowed;
        }

        > label {
            cursor: inherit;
        }
    }
}


.upload-manager {
    position: fixed;
    bottom: $notification-offset-y;
    right: $notification-offset-x;
    z-index: z-index(dropdowns);

    background-color: color(base-000);
    border-radius: $large-border-radius;
    width: $notification-width;
    overflow: hidden;
    display: none;
    box-shadow: $uploader-box-shadow;

    &.active {
        display: block;
    }

    &.collapsed {
        width: auto;

        .collapse {
            transform: rotate(180deg);
        }

        section.uploads {
            display: none;
        }
    }

    header {
        @include font-weight(semibold);
        background-color: color(base-700);
        color: color(base-000);
        padding: box-model(xs, s);

        .close {
            color: color(base-000);
            margin-right: box-model(s) * -0.5;
        }

        .collapse {
            @include iconify(ic-chevron-down);
            @include font-size(xxm);
            cursor: pointer;
            float: right;
            width: icon-size(s);
            height: icon-size(s);
            display: block;
            text-align: center;
            color: color(base-000);
            margin-top: -5px; // hardcoded to fit the close icon (see `.close` on `buttons.scss`)

            &::before {
                vertical-align: middle;
            }
        }
    }

    .batch-actions {
        background-color: color(primary-050);
        padding: box-model(xs, s);
    }

    .items {
        max-height: box-model(xs) * 30;
        overflow: auto;
    }
}

.upload-progress {
    padding: box-model(xs, s);
    display: flex;

    &:hover {
        background-color: color(base-100);

        .actions {
            display: block;
        }
    }

    &.canceled {
        .name {
            color: color(base-500);
        }
    }

    .name {
        @include ellipsis;
        @include line-height(base);
        flex: 1 1 60%;
        padding-right: box-model(s);
    }

    .actions {
        display: none;
        padding: box-model(0, s);
    }

    .status {
        svg {
            display: block;
        }
    }

    .completed {
        color: color(base-000);
        background-color: color(success-300);
        height: icon-size(xs);
        width: icon-size(xs);
        border-radius: 100%;
        text-align: center;

        &.error {
            background-color: color(error-300);
        }

        i {
            @include line-height(base);
            @include font-size(xm);
        }
    }

    .loader {
        min-height: icon-size(xs);

        > .loading {
            position: static;
        }

        .spinner {
            height: box-model(l);
            width: box-model(l);
            display: block;
            margin-top: 0;
            margin-left: 0;
        }
    }

}
