// Abstracts
@use "abstracts/index" as *;

.live-stream-export {
    .progress {
        margin-top: 0;
    }
    .advanced-exports {
        margin-top: box-model(m);
    }
}
