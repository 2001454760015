// Abstracts
@use "abstracts/index" as *;
@use "default/components/cards/base" as *;

.app-brandings-list {
    .column-actions .well .input-block-level {
        box-sizing: border-box;
        margin-bottom: 10px;
    }

    .no-requests-screen {
        text-align: center;
        line-height: 2em;
        color: #999;
        margin-top: 5em;

        i {
            display: inline-block;
            font-size: 5em;
            margin: 0 .2em .4em .2em;
        }

        span {
            font-size: 1.5em;
        }
    }

    .search-filter {
        display: block;
        padding: 5px 0 0 0;
        border-bottom: 1px solid #eaeaea;
        text-align: right;

        div[ng-switch] {
            display: inline-block;
        }

        span {
            margin-top: 5px;
        }

        select {
            margin: 0 0 5px 0;
        }
    }
}

.branded-app-configuration-view {
    .nav-list .select-item-link .icon-warning {
        margin-top: 2px;
    }

    label .help-block {
        margin-top: 0;
    }
}

.branded-app-asset-uploader {
    padding: 10px;
    text-align: center;

    .icon-spinner, .new-spinner {
        position: relative;
        margin-top: 30px;
    }

    .asset-container {
        position: relative;
        display: inline-block;
        margin-bottom: 10px;

        .asset {
            display: none;
        }

        .missing-asset {
            display: none;
        }

        input[type='file'] {
            display: none;
            position: absolute;
            opacity: 0;
            top: 0;
            left: 0;
            cursor: pointer;
        }
    }

    .asset-container.loaded:not(.uploading),
    .asset-container.missing:not(.uploading) {
        .icon-spinner, .new-spinner {
            display: none;
        }

        input[type='file'] {
            display: block;
        }
    }

    .asset-container.missing:not(.uploading) {
        border: 1px solid #ddd;
        border-radius: 4px;
        box-sizing: border-box;

        &:hover {
            background-color: #f3f3f3;
        }

        .missing-asset {
            display: block;
            top: 20px;
            font-size: 2em;
            color: #999
        }

        .asset {
            display: none;
        }
    }

    .asset-container.loaded:not(.uploading) {
        &:hover {
            opacity: 0.8;
        }

        .asset {
            display: inline-block;
            background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAAAAAA6mKC9AAAAGElEQVR4AWM8wwABxlB6oAT+QxlnB1YAAJ1iFml5blKlAAAAAElFTkSuQmCC');
        }
    }

    .asset-tools {
        position: absolute;
        right: 5px;
        bottom: 5px;
        text-align: right;
        display: none;
        vertical-align: bottom;
        line-height: 1px;
    }

    .asset-container:hover .asset-tools {
        display: block;
    }

    .dimensions {
        font-size: 0.9em;
        opacity: 0.8;
    }

    .asset-container.loading,
    .asset-container.missing {
        .asset-tools .btn {
            display: none;
        }
    }
}

*[disabled] .branded-app-asset-uploader {
    .asset-container input[type='file'] {
        cursor: not-allowed !important;
    }
}

.branded-app-screenshot-uploader {
    .screenshots-wrapper {
        overflow-x: auto;
        overflow-y: hidden;
    }

    .branded-app-asset-uploader {
        display: inline-block;
        padding: 0 5px;
        vertical-align: top;

        &:first-child {
            padding-left: 0;
        }
        &.last {
            padding-right: 0;
        }
    }
}

.app-branding-editor-section {
    // fieldset by default fieldset takes full width of the content (?!)
    min-width: 0;

    legend {
        margin-bottom: 10px;
    }

    &-dashboard {
        .span3 {
            text-align: center;

            .app-icon {
                cursor: pointer;
            }
        }

        .publishing-request-card {
            padding-top: 10px;
            padding-bottom: 0;

            h4 {
                padding-top: 15px;
                padding-bottom: 5px;
            }

            .table {
                margin: 0;
            }

            .btn-toolbar {
                background: #ddd;
                margin: 10px -20px 0 -20px;
                padding: 5px 15px 1px 15px;
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }

        .table-changelog {
            tr.first td {
                border-top: none;
            }

            .cell-icon {
                width: 1%;
            }
            .cell-description {
                width: 70%;
            }
            .cell-date {
                width: 29%;
            }
        }
    }

    &-app-info {
        .branded-app-asset-uploader {
            .icon-spinner, .new-spinner {
                position: relative;
                margin-top: -300px;
            }

            .asset-container {
                height: 370px !important;
                line-height: 370px !important;

                .asset {
                    vertical-align: middle;
                }
            }

            input[type='file'] {
                height: 370px !important;
            }
        }
    }

    &-activation-screen {
        .activation-pages-wrapper {
            width: 100%;
            overflow: auto;
        }

        .activation-page-box {
            position: relative;
            display: inline-block;
            cursor: pointer;
            width: 128px;
            height: 128px;
            line-height: 128px;
            text-align: center;
            vertical-align: middle;
            border: 1px solid #ddd;
            border-radius: 4px;
            box-sizing: border-box;
            margin: 0 5px 0 0;
            user-select: none;
            overflow: hidden;

            .icon-plus {
                color: #999;
                font-size: 2em;
                vertical-align: middle;
            }

            &.active {
                border-color: #34a0ed;
                border-width: 2px;
                background-color: #ebf6fd;
                cursor: default;
            }

            .activation-page-controls {
                position: absolute;
                bottom: 5px;
                right: 5px;
                left: 5px;
                display: none;
                line-height: 0;
                text-align: center;
            }

            &:hover .activation-page-controls {
                display: block;
            }

            /* compensate for the missing buttons */
            &:first-child {
                .activation-page-controls {
                    padding-left: 28px;
                }
            }
            &:nth-last-child(2) {
                .activation-page-controls {
                    padding-right: 28px;
                }
            }
        }
    }

    &-app-i18n {
        select, .select2-container {
            display: inline-block;
            width: 300px;
        }

        form {
            margin: 0;
        }

        .table.table-plain {
            margin: 20px 0 0 0;
        }

        .padded-container {
            text-align: right;

            small {
                font-size: 0.8em;
                opacity: 0.8em;
            }
        }
    }

    &-store-info {
        .form-generator-array-field-container td {
            padding: 0;
        }
    }
}

.branded-app-preview-container {
    cursor: default;
    position: relative;
    user-select: none;

    *[ng-style] {
        transition: background-color .2s ease, color .2s ease;
    }

    .statusbar {
        text-align: right;
        font-size: 0.9em;
        color: #fff;
    }

    .statusbar, .titlebar {
        padding: 0 5px;
    }

    .statusbar, .titlebar, .toolbar {
        vertical-align: middle;
    }

    .titlebar, .toolbar {
        text-align: center;

        .btn-group {
            margin: 0 auto;
        }
    }

    .titlebar {
        padding: 5px;
    }

    .event-list {
        background: #f3f3f3;
        padding: 5px 10px 20px 10px;
        height: 300px;
    }

    .section-title {
        text-transform: uppercase;
        margin-top: 15px;
        color: #c0c0c0;
        text-indent: 5px;
    }

    .past-event-box {
        background: #fff;
        border-radius: 4px;

        .line3 {
            font-size: 0.8em;
            color: #999;
        }
    }

    .past-event-box {
        padding: 5px;

        .event-icon {
            text-align: center;
            font-size: 3em;
            padding: 15px 0;
        }
    }

}

branded-app-email-status {
    .table-wrapper {
        margin-bottom: box-model(m);
    }
    .card-list {
        margin-bottom: box-model(m);
        display: flex;
        column-gap: box-model(m);
    }
    .card {
        @extend %card-with-borders;
        padding: box-model(m);
        width: box-model(xs) * 24;
        box-sizing: border-box;

        .counter {
            @include font-size(xxm);
            @include font-weight(semibold);
        }

        .count {
            display: inline-block;
        }

        .label {
            color: color(base-600);
        }
    }
}
