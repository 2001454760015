// Abstracts
@use "abstracts/index" as *;

.person-activity-feed {

    h5 {
        margin-bottom: box-model(s);
    }

    .activities {
        border-radius: $small-border-radius;
        border: 1px solid color(base-300);
        margin-bottom: box-model(l);
        overflow: hidden;
    }

    .activity {
        display: flex;
        gap: box-model(xs);
        padding: box-model(xs, s);

        &:nth-child(odd) {
            background-color: color(base-100);
        }

        i {
            margin-top: .4em; /* the usual icon hack */
        }

        p {
            flex: 1;
            margin: 0;
        }
    }

    details {
        flex: 1;

        summary {
            cursor: pointer;
            color: color(primary-300);
            list-style: none;
        }

        .table-wrapper {
            margin-top: box-model(m);
        }
    }

    span.date {
        float: right;
        color: color(base-700);
    }

    dl {
        margin-bottom: 0;
    }

    dt {
        width: 10%;
    }

    dd {
        margin-left: calc(10% + #{box-model(m)});
    }
}
