// Abstracts
@use "abstracts/index" as *;

.conditions-editor {
    @include clearfix;
    padding-top: box-model(xs);
    display: flex;

    .legend {
        @include font-size(s);
        @include font-weight(semibold);
        @include line-height(document);
        color: color(success-500);
        margin-right: box-model(s);
        padding: box-model(xs, 0);
        white-space: nowrap;
    }

    .badge {
        white-space: nowrap;
        margin-left: 0;
    }

    .editor-actions {
        margin-top: box-model(xs);
    }

    .condition-rule {
        + .condition-rule {
            margin-top: box-model(xs);
        }

        .dropdown.field-selector,
        .dropdown.value-selector {
            @include line-height(document);
            height: box-model(xl);
            vertical-align: baseline;
        }
    }
}

.predicates-editor {
    .separator { // "and" and "or"
        @include line-height(document);
        @include font-size(s);
        @include font-weight(semibold);

        color: color(base-600);
        display: inline-block;
        margin: box-model(0, xs);
        vertical-align: middle;
    }

    .rules > .separator { // "or"
        margin: box-model(0, xs);
    }
}

.condition-rule {
    display: inline;

    .dropdown {
        &:only-child .activator {
            border-radius: $base-border-radius;
        }

        .dropdown-vue.anchor-nw.menu {
            margin-left: 0;
        }
    }

    .predicate {
        background-color: color(base-100);
        display: inline-block;
        margin-bottom: box-model(s);

        &.editing {
            padding-right: 0;

            .outer-wrapper {
                margin-right: 0;

                .value, .add, .remove {
                    display: none;
                }
            }
        }

        &.read-only {
            .outer-wrapper .field-value-wrapper {
                opacity: 0.8;
                cursor: not-allowed;
            }
        }

        &:not(.editing) {
            .outer-wrapper:hover {
                color: color(error-300);
            }

            .field-value-wrapper:hover {
                .remove {
                    color: color(error-300);
                    background-color: color(base-100);
                    display: block;

                    &:hover {
                        background-color: color(error-300);
                        color: color(base-000);
                    }
                }
            }
        }

        &:first-child {
            border-top-left-radius: $base-border-radius;
            border-bottom-left-radius: $base-border-radius;
        }

        &:last-child {
            border-top-right-radius: $base-border-radius;
            border-bottom-right-radius: $base-border-radius;

            &.more-possible {
                padding-right: 0;

                .remove {
                    right: box-model(-xxs);
                }
            }

            &.no-more {
                .remove {
                    border-top-right-radius: $base-border-radius;
                    border-bottom-right-radius: $base-border-radius;
                }
            }

            .add {
                display: inline-block;
            }

            .value-selector .activator {
                border-top-right-radius: $base-border-radius;
                border-bottom-right-radius: $base-border-radius;
            }
        }

        .remove {
            display: none;
        }
    }

    .field {
        @include font-weight(semibold);

        padding: box-model(xs, 0);
    }

    .activator, .outer-wrapper {
        @include font-size(s);
        @include line-height(document);

        text-transform: none;
        display: inline-block;
    }

    .activator {
        @include font-weight(normal);
        padding: box-model(xs);
        cursor: pointer;
        vertical-align: middle;
    }

    .outer-wrapper {
        font-size: 0;
        vertical-align: middle;
        white-space: nowrap;

        .field-value-wrapper {
            cursor: pointer;
            display: inline-block;
            position: relative;
        }

        .field, .value, .add, .remove, .separator {
            @include font-size(s);
            vertical-align: middle;
        }

        .field {
            display: inline-block;
            padding-right: box-model(xxs);
            padding-left: box-model(xs);
        }

        .value {
            @include font-weight(normal);
            padding-right: box-model(xs);
        }

        .add, .remove {
            @include font-size(document);
            cursor: pointer;
            text-align: center;
            width: box-model(xl);
            height: box-model(xl);

            i {
                @include line-height(xxm);
                display: inline-block;
            }
        }

        .add {
            border-top-right-radius: $base-border-radius;
            border-bottom-right-radius: $base-border-radius;
            color: color(base-700);
            background-color: color(base-100);
            display: inline-block;
            border-left: 1px solid color(base-300);
            margin-left: box-model(xxs);
            display: none;

            &:hover {
                background-color: color(base-300);
            }
        }

        .remove {
            position: absolute;
            top: 0;
            right: 0;

            &::before {
                content: '';
                display: block;
                background: linear-gradient(90deg, transparent, color(base-100) 100%);
                height: box-model(xl);
                width: line-height-original(xxs);
                position: absolute;
                top: 0;
                left: line-height-original(xxs) * -1;
                pointer-events: none;
            }
        }
    }

    .field-selector,
    .value-selector {
        .activator {
            background-color: color(base-300);
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            color: color(base-700);
        }
    }
}

