// Abstracts
@use "abstracts/index" as *;

.hybrid-helper-block {
    border: 1px solid color(base-300);
    padding: 13px;
    border-radius: 8px;

    display: flex;
    justify-content: space-between;
    background-color: color(base-050);

    a {
        @include font-size(xs);
        @include font-weight(bold);
        text-transform: uppercase;
        cursor: pointer
    }
}
