// Abstracts
@use "abstracts/index" as *;

.events-counter {
    display: flex;
    height: box-model(xl);

    .count {
        @include font-size(xxm);
        @include line-height(xxm);

        color: color(base-500);
        width: box-model(xl);
        text-align: center;

        &.active {
            color: color(primary-300);
        }
    }

    .type {
        @include font-weight(bold);
        @include font-size(base);
        @include line-height(xxs);
    }

    .labels {
        font-size: 0;

        span {
            @include font-size(s);
            @include line-height(xxxs);

            color: color(base-500);
        }
    }
}
