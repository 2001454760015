@use "sass:math";

// Abstracts
@use "abstracts/index" as *;

.list-quick-editor {
    border: 1px solid color(base-300);
    border-left: 0;
    width: box-model(xxl) * 8;
    overflow: hidden;
    transition: width .2s ease-in-out;

    .quick-editor-open & {
        overflow: visible;
    }

    &.open-enter-active,
    &.open-leave-active {
        width: 0;

        .editor {
            opacity: 0;
        }
    }

    &.open-enter-from,
    &.open-leave-to {
        width: 0;

        .editor {
            opacity: 0;
        }
    }

    .editor {
        opacity: 1;
        width: box-model(xxl) * 8;
        transition: opacity .2s linear .3s;
    }

    .inner-wrapper {
        padding: box-model(l);

        .close {
            margin-right: box-model(xs) * -1;
        }
    }

    .side-title {
        margin-bottom: box-model(m);
        margin-right: box-model(xl);
    }

    h3 {
        @include line-height(xm);

        word-break: break-word;
        border: 0;
        // The 1px addition is for compensate for the editable
        // input when the two elements switches
        margin-top: 1px;
        margin-bottom: box-model(l) + 1px;
        padding-bottom: 0;

        &.editable {
            border-radius: $base-border-radius;
            cursor: text;

            &:hover {
                box-shadow: 0 0 0 2px color(primary-300);
            }
        }
    }
}
