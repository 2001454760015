// Abstracts
@use "abstracts/index" as *;

.pax-manager {
    > .actions {
        float: right;
    }

    .search-bar {
        margin-bottom: box-model(xl);

        .search-input-wrapper > input {
            min-width: 320px;
        }
    }

    .user-deleted {
        text-align: center;
        margin: box-model(h, 0, 0, 0);
    }

    .table-wrapper {
        overflow: visible;
    }
}
