@use "sass:math";

// Abstracts
@use "abstracts/index" as *;

.expand-details {
    border: 1px solid color(base-300);
    border-radius: $large-border-radius;
    margin-bottom: box-model(l);
    padding: box-model(s, m);

    details {

        &:has(.content:empty) {
            summary {
                cursor: default;

                > i {
                    display: none;
                }
            }
        }

        summary {
            display: grid;
            grid-template-columns: auto 1.5rem;
            list-style: none;
            font-weight: bolder;
            cursor: pointer;

            > i {
                margin-top: box-model(xxs);
            }

            .ic-chevron-up {
                display: none;
            }

            .ic-chevron-down {
                display: inline-block;
            }

            .description {
                font-weight: normal;
            }
        }

        .content {
            margin-top: box-model(m);

            &:empty {
                display: none;
            }
        }
    }

    details[open] {
        .ic-chevron-up {
            display: inline-block;
        }

        .ic-chevron-down {
            display: none;
        }
    }
}
