// Abstracts
@use "abstracts/index" as *;

.send-emails-modal {
    .target {
        // we can't use last, because multiselect adds two other "hidden" li after.
        li.multiselect__element:nth-of-type(7) {
            border-top: 1px solid color(base-300);
        }
    }

    .schedule-toggler {
        margin-bottom: box-model(m);
    }

    .schedule-time-pickers {
        display: flex;
        justify-content: center;
        column-gap: box-model(xl);

        > div {
            flex: 0 0 auto;
        }
    }

    .schedule-warning {
        background: color(warning-100);
        border: 1px solid color(warning-300);
        border-left-width: box-model(xs);
        border-radius: box-model(xxs);
        padding: box-model(s, m);
        margin-top: box-model(m);

        ul {
            list-style: none;
            margin: box-model(0, xs);
            padding: 0;
        }
        li {
            @include font-weight(bold);
        }
    }

    .footer-actions {
        position: relative;

        .email-navigation {
            position: absolute;
            left: 0;
            cursor: pointer;
        }
    }
}

.timepicker-scheduled-email {
    .mx-time {
        width: box-model(xxl) * 5;
    }
}
