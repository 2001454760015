// Abstracts
@use "abstracts/index" as *;

.create-webinar-template {
    h1 {
        text-align: center;
    }
}

.card.hovering.webinar-template-card {

    .content-text {
        min-height: 0px;
    }

    .footer {
        display: flex;
        align-items: center;
        padding-top: box-model(xs);

        .tags {
            flex-grow: 1;
        }

        .delete {
            color: color(error-300);
            flex-grow: 0;
        }
    }
}
